import { Component, OnInit } from '@angular/core';
import { AskthetutorService } from '../../home/askthetutor/askthetutor.service';
import { ResMessage, User } from '../../resources/resources';
import { GlobalmessageService } from '../../../service/globalmessage.service';
import { Ask } from '../../home/askthetutor/askthetutor';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-my-consult',
  templateUrl: './my-consult.component.html',
  styleUrls: ['./my-consult.component.css']
})
export class MyConsultComponent implements OnInit {

  datasource: Array<Ask>;
  viewsource: Array<Ask>;
  user: User;
  constructor(
    private _asks: AskthetutorService,
    private router: Router,
    private route: ActivatedRoute,
    private _gms: GlobalmessageService
  ) {
    this._gms.getUserMessage()
      .subscribe(message => {
        if (message) {

        } else {
          this.user = message.data
        }
      });

  }
  ngOnInit() {
    this.user = this._gms.getUserinfo_init();
    this.getAsk();
  }

  showDetail(ask: Ask) {
    if (this.user.type == 6 || this.user.type == 0 || this.user.type == 1) {
      if (ask.flag == 2) {
        this._gms.setItems('conask', ask);
        this.router.navigate(['../', 'myconsultdetail'], { relativeTo: this.route });
      }
    }
  }

  choose(e) {
    if (e.selectedIndex == 0) {
      this.viewsource = this.datasource.filter(p => p.flag == 2);
    } else {
      this.viewsource = this.datasource.filter(p => p.flag == 4);
    }
  }

  getAsk() {
    if (this.user.type >= 8) {
      this._asks.getUser({ id: this.user.id }).then(res => {
        let data = res as ResMessage;
        if (data.state == 0) {
          this.datasource = data.msg;
          this.viewsource = this.datasource.filter(p => p.flag == 2);
        }
      })
    } else if (this.user.type == 6 || this.user.type == 0 || this.user.type == 1) {
      this._asks.getTec({}).then(res => {
        let data = res as ResMessage;
        if (data.state == 0) {
          this.datasource = data.msg;
          this.viewsource = this.datasource.filter(p => p.flag == 2);
        }
      })
    }
  }
}
