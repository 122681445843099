<app-navtool [title]="'购买'"  [backurl]="'/'"></app-navtool>
<div style="height:45px"></div>
<div style="height:100%">
    <WhiteSpace size='lg'></WhiteSpace>
    <WingBlank size='md'>
        <List>
            <ListItem>
                <div style="text-align: center;width:100%;line-height:50px;"><span style="font-size: 18px;">生涯规划</span>
                </div>
            </ListItem>
            <ListItem>
                <div style="text-align: center;width:100%;line-height:40px;"><span
                        style="font-size: 16px;">{{free_number|number}} 元</span></div>
            </ListItem>
            <InputItem [placeholder]="'请输入收件地址'" [error]="isErrorAddres" [focus]="onFocusAddress" [clear]="true"
                [content]="'收件地址'" id="address" (onErrorClick)="inputErrorClick($event,'address')"
                (onChange)="inputChange($event,'address')">
            </InputItem>
            <InputItem [placeholder]="'请输入联系电话'" [value]="order.phone" [maxLength]="11" [error]="isErrorPhone" [focus]="onFocusPhone" [clear]="true"
                [content]="'联系电话'" id="phone" (onErrorClick)="inputErrorClick($event,'phone')"
                (onChange)="inputChange($event,'phone')">
            </InputItem>
            <InputItem [placeholder]="'请输入收件人'" [value]="order.name" [error]="isErrorName" [focus]="onFocusName" [clear]="true" [content]="'收件人'"
                id="name" (onErrorClick)="inputErrorClick($event,'name')" (onChange)="inputChange($event,'name')">
            </InputItem>
            <ListItem [extra]="_stepper">选择数量</ListItem>
            <ListItem>
                <Flex [justify]="'center'" style="width:100%">
                    <div style="width:80%;text-align: center;" Button class="sendbtn" (click)="onSubmitOrder()"
                            [type]="'primary'">微信支付</div>
                </Flex>
            </ListItem>
        </List>
    </WingBlank>
</div>
<ng-template #_stepper>
    <Stepper [value]="value" [min]="1" [showNumber]="true" (onChange)="setpperChange($event)"></Stepper>
</ng-template>