import { analyzeAndValidateNgModules } from '@angular/compiler';

export class School {
    sid: number;
    name: string;
    zone: string;
}

export class Zone {
    zone: string
}

export class MajorSelect {
    yearItems: any;
    batchItems: any;
    subjectItems: any;
    zoneItems: any;
    zoneSchoolItems: any;
    zoneItemsThree: any;
}

export class User {
    id: number;
    type: number;
    name: string;
    phone: string;
    sex: string;
    age: number;
    image: string;
    password: string;
    token: string;
    openid: string;
    nickname: string;
    language: string;
    headimgurl: string;
    country: string;
    province: string;
    city: string;
    createtime: number;
    lastlogin: number;
    idintidy_no: number;
}

export class WxUserinfo {
    openid: string;
    nickname: string;
    language: string;
    headimgurl: string;
    country: string;
    province: string;
    city: string;
    createtime: number;
    lastlogin: number;
    privilege: any;
    sex: string;
}

export class OutMessage {
    type: Number;//1.Name
    data: any;
}

export class ResMessage {
    msg: any;
    code: number;
    state: number;
}



export class Authorize {
    id: number;
    uid: number;
    license: string;
    flag: boolean;
    type: number;
}

export class Order {
    id: number;
    uid: number;
    serverno: string;
    goodid: number;
    goodname: string;
    goodnum: number;
    goodfree: number;
    orderamount: number;
    ifpay: boolean;
    paytime: number;
    delivery: string;
    expressnumber: string;
    status: number;
    createtime: number;
    flag: boolean;
    ifsend: boolean;
    address: string;
    phone: string;
    name: string;
    flashtime: number;
}