import { Component, OnInit } from '@angular/core';
import { User, Authorize, ResMessage } from '../../resources/resources';
import { ClipboardService } from 'ngx-clipboard'
import { GlobalmessageService } from 'src/app/service/globalmessage.service';
import { MyService } from '../my.service';

@Component({
  selector: 'app-my-authorize',
  templateUrl: './my-authorize.component.html',
  styleUrls: ['./my-authorize.component.css']
})
export class MyAuthorizeComponent implements OnInit {
  user: User;
  checkItemListData = [
    { value: 0, name: '一次性', checked: false },
    { value: 1, name: '七天内有效', checked: false },
    { value: 2, name: '一个月内有效', checked: false },
    { value: 3, name: '半年内有效', checked: false },
    { value: 4, name: '一年内有效', checked: false },
    { value: 5, name: '三年内有效', checked: false }
  ];

  selectedStatus1 = { value: 0, name: '只能使用一次', checked: false };

  license: string;
  constructor(
    private _clip: ClipboardService,
    private _mys: MyService,
    private _gms: GlobalmessageService
  ) {
    this.user = this._gms.getUserinfo_init();
  }

  ngOnInit() {

  }

  onChange = (val: any) => {

  }

  onClick() {
    let type = this.selectedStatus1.value;
    this._mys.getUserAuthorize(type).then(data => {
      let tmp = data as ResMessage;
      if (tmp.state == 0) {
        this.license = tmp.msg;
        this._clip.copyFromContent(tmp.msg);
      } else {
        this.license = '生成邀请码失败';
      }
    })
  }

  createLicense(num: number) {
    let dt = new Date();
    if (num == 0) {
      let number = Math.ceil(dt.getTime() / 1000);
      return number.toString(16).toUpperCase();
    } else if (num == 1) {
      let number = Math.ceil(dt.setDate(dt.getDate() + 7) / 1000);
      return number.toString(16).toUpperCase();
    } else if (num == 2) {
      let tmp = dt.setMonth(dt.getMonth() + 1);
      let number = Math.ceil(tmp / 1000);
      return number.toString(16).toUpperCase();
    } else if (num == 3) {
      let tmp = dt.setMonth(dt.getMonth() + 6);
      let number = Math.ceil(tmp / 1000);
      return number.toString(16).toUpperCase();
    } else if (num == 4) {
      let tmp = dt.setMonth(dt.getMonth() + 12);
      let number = Math.ceil(tmp / 1000);
      return number.toString(16).toUpperCase();
    } else if (num == 5) {
      let tmp = dt.setMonth(dt.getMonth() + 36);
      let number = Math.ceil(tmp / 1000);
      return number.toString(16).toUpperCase();
    }
  }

}
