import { Component, OnInit } from '@angular/core';
import { GlobalmessageService } from 'src/app/service/globalmessage.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-sygh',
  templateUrl: './sygh.component.html',
  styleUrls: ['./sygh.component.css']
})
export class SyghComponent implements OnInit {

  constructor(
    private _gms: GlobalmessageService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
  }

  onLeftClick() {
    this.router.navigate(['../']);
  }

  onPay(){
    this.router.navigate([{ outlets: { popup: 'shopcheck' }}]);
  }

}
