import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': '*'
  })
};

@Injectable({
  providedIn: 'root'
})
export class DbsService {
  public restServer;
  public http;
  constructor(Http: HttpClient) {
    this.http = Http;
    this.restServer = '/tapi/';
  }

  public set(token: any) {
    httpOptions.headers =
      httpOptions.headers.set('Authorization', token);
  }

  public async get(url: string, params?: Object) {
    let httpParams = new HttpParams();
    const vm = this;
    return new Promise(resolve => {
      if (params) {
        for (const key in params) {
          if (params[key]) {
            httpParams = httpParams.set(key, params[key]);
          }
        }
      }
      vm.http.get(vm.restServer + url, { params: httpParams }, httpOptions).subscribe(data => {
        resolve(data);
      })
    })
  }

  public async post(url: string, data?: Object, options?: Object) {
    const vm = this;
    return new Promise(resolve => {
      vm.http.post(vm.restServer + url, data, options, httpOptions)
        .subscribe(res => {
          resolve(res);
        });
    })
  }


  public put(url, data?: Object, cb?: Function, options?: Object) {
    const vm = this;
    vm.http.put(vm.restServer + url, data, options, httpOptions)
      .subscribe(res => {
        cb(res);
      });
  }

  public delete(url, params?: Object, cb?: Function) {
    let httpParams = new HttpParams();
    const vm = this;
    if (params) {
      for (const key in params) {
        if (params[key]) {
          httpParams = httpParams.set(key, params[key]);
        }
      }
    }
    vm.http.delete(vm.restServer + url, { params: httpParams }, httpOptions)
      .subscribe(data => {
        cb(data);
      });
  }
}
