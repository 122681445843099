<Card>
    <CardHeader [title]="title" [extra]="extra" [thumb]="thumb">
        <ng-template #title>
            <span style="color:#1296db">{{ask.name}}</span>
        </ng-template>
        <ng-template #extra>
            <span>{{checkTime(ask.createtime)}}</span>
        </ng-template>
        <ng-template #thumb>
            <svg style="height:32px;width:32px;margin-right:16px" t="1584243928883" class="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="910" width="200" height="200">
                <path
                    d="M910.336 56.6784a92.59008 92.59008 0 0 0-94.55104-5.15584L122.47552 408.41216a93.5936 93.5936 0 0 0 22.42048 174.5664l158.61248 35.46112a25.32352 25.32352 0 0 1 19.16928 18.7136l54.19008 218.20928a72.74496 72.74496 0 0 0 127.02208 28.3648l53.16608-65.31072a25.472 25.472 0 0 1 37.22752-2.46784L739.09248 952.32a92.9536 92.9536 0 0 0 63.92832 25.6 94.35648 94.35648 0 0 0 34.21696-6.47168 92.84096 92.84096 0 0 0 59.45856-80.35328l55.3472-749.46048A92.48768 92.48768 0 0 0 910.336 56.6784z m-81.62816 829.40416a25.472 25.472 0 0 1-42.86464 16.65536L641.024 766.35648a93.95712 93.95712 0 0 0-64.27136-25.47712q-3.072 0-6.144 0.19968a93.93152 93.93152 0 0 0-66.46784 34.33984L451.00544 840.704a4.56192 4.56192 0 0 1-7.97184-1.77664l-54.19008-218.18368A93.09696 93.09696 0 0 0 318.40256 551.936l-158.60736-35.46112a25.45664 25.45664 0 0 1-6.10816-47.48288l693.2992-356.864a25.472 25.472 0 0 1 37.0688 24.50944z"
                    fill="#1296db" p-id="911"></path>
                <path
                    d="M690.1504 321.94048l-207.80544 234.6496a86.48704 86.48704 0 0 0-22.63552 50.176l-7.36768 74.61888a34.0736 34.0736 0 0 0 30.57152 37.25312q1.71008 0.16896 3.38944 0.16896a34.08384 34.08384 0 0 0 33.87904-30.72l7.3728-74.61376a17.97632 17.97632 0 0 1 4.80768-10.53696L741.20192 367.104a34.07872 34.07872 0 0 0-51.05152-45.16352z"
                    fill="#1296db" p-id="912"></path>
            </svg>
        </ng-template>
    </CardHeader>
    <CardBody>
        <div>{{ask.message}}</div>
        <WhiteSpace [size]="'md'"></WhiteSpace>
        <div *ngIf="ask.flag==4">
            <hr style= "border:1px dashed #999" />
            <WhiteSpace [size]="'md'"></WhiteSpace>
            <div>导师回复：{{ask.replymessage}}</div>
        </div>
    </CardBody>
    <CardFooter [content]="dateConvert(ask.createtime)" [extra]="footerExtra">
        <ng-template #footerExtra>
            <div>申请退款</div>
        </ng-template>
    </CardFooter>
</Card>
<WhiteSpace [size]="'md'"></WhiteSpace>