import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Cp, CpItems, CpAnwList, CpSore } from './cp';
import { CpInfo, CpItemsList } from './cp-cache-data';
import { User } from '../../resources/resources';
import { DbsService } from '../../../service/dbs.service';
import { MajorSelect, ResMessage } from '../../resources/resources';
import { MajorSelectValues, MajorCheckEnrollment } from '../../marjors/major';

@Injectable({
  providedIn: 'root'
})
export class CpService {

  private cpInfo$: BehaviorSubject<Cp> = new BehaviorSubject<Cp>(CpInfo);
  private cpItems$: BehaviorSubject<CpItems[]> = new BehaviorSubject<CpItems[]>(CpItemsList);
  constructor(
    private _dbs: DbsService
  ) { }

  getCpInfo() {
    return this.cpInfo$;
  }

  getCpItems() {
    return this.cpItems$;
  }

  checkCp(user: User, cpAnwList: CpAnwList[]) {
    if (Object.keys(user).length > 0) {

    } else {

    }
  }

  sumCp(cpAnwList: CpAnwList[]) {
    let promise = new Promise((resolve, reject) => {
      let score = 0;
    });
    return promise;
  }

  async getUserAuthorizeInfo() {
    let result = await this._dbs.get('cp/get/authorize')
    return result;
  }

  async getCpMajor(ms: MajorSelectValues) {
    let result = await this._dbs.get('cp/get/view', ms)
    return result;
  }

  async getMajorDetail(mce: MajorCheckEnrollment) {
    let result = await this._dbs.get('cp/get/majordetail', mce)
    return result;
  }

  async getCpTry() {
    let result = await this._dbs.get('cp/get/getauthorizetry')
    return result;
  }

  async setCpTry() {
    let result = await this._dbs.get('cp/set/setauthorizetry')
    return result;
  }

  async getPlan(planid) {
    let result = await this._dbs.get('v3/cp/plan', { planid: planid })
    return result;
  }

  async subAnswerList(args) {
    let result = await this._dbs.post('v3/cp/subanswerlist', args)
    return result;
  }

  async addUserPlan() {
    let result = await this._dbs.get('v3/cp/adduserplan')
    return result;
  }

  async planResult(args) {
    let result = await this._dbs.post('v3/cp/planresult', args)
    return result;
  }

  async cpResult() {
    let result = await this._dbs.get('v3/cp/subject')
    return result;
  }

  async endResult() {
    let result = await this._dbs.get('v3/cp/endplanresult')
    return result;
  }

  async checkIdinCode(code) {
    let result = await this._dbs.get('v3/user/idincode', { code: code })
    return result;
  }
}
