import { Component, OnInit } from '@angular/core';
import { ToastService } from 'ng-zorro-antd-mobile';
import { GlobalmessageService } from 'src/app/service/globalmessage.service';
import { Router } from '@angular/router';
import { DbsService } from 'src/app/service/dbs.service';

@Component({
  selector: 'app-query-mojar-class',
  templateUrl: './query-mojar-class.component.html',
  styleUrls: ['./query-mojar-class.component.css']
})
export class QueryMojarClassComponent implements OnInit {

  datasource: any;
  dataview: any;
  user_result: any;
  pageLimit = 20;
  public directionCount = 0;
  page = 0;
  state = {
    refreshState: {
      currentState: 'deactivate',
      drag: false
    },
    direction: 'up',
    endReachedRefresh: false,
    height: 44,
    data: [],
    directionName: 'both up and down'
  };
  dtPullToRefreshStyle = { height: this.state.height + 'vh', padding: '0px' };
  zn = '选择区域';
  zoneItems = ['全部', '北京', '天津', '河北', '山西', '内蒙古', '辽宁', '吉林', '黑龙江', '上海', '江苏',
    '浙江', '安徽', '福建', '江西', '山东', '河南', '湖北', '湖南', '广东', '广西', '海南',
    '重庆', '四川', '贵州', '云南', '陕西', '甘肃', '宁夏', '新疆', '西藏', '青海'];

  getResult(result) {
    this.value = [];
    let temp = '';
    result.forEach(item => {
      this.value.push(item.label || item);
      temp += item.label || item;
    });
    return this.value.map(v => v).join(',');
  }

  value = [];

  getValue(result) {
    let value = [];
    let temp = '';
    result.forEach(item => {
      value.push(item.value || item);
      temp += item.value || item;
    });
    return value;
  }
  zone = [];
  schname = '';
  constructor(
    public _toast: ToastService,
    private _gms: GlobalmessageService,
    private router: Router,
    private dbs: DbsService

  ) { }

  ngOnInit() {
    //const toast = this._toast.fail('请先选择学科再进行匹配！', 1500);
    this.gerMojar()
  }

  ngAfterViewChecked() {

  }

  onSchChange(e) {
    this.schname = e;
    this.viewfile();
  }

  viewfile() {
    if (this.zn == '全部' || this.zn == '选择区域') {
      if (this.schname != '') {
        let schs = this.datasource.sch;
        this.dataview = schs.filter(p => p.sname.indexOf(this.schname) > -1);
      } else {
        this.dataview = this.datasource.sch;
      }
      setTimeout(() => {
        this.state.data = [];
        this.page = 0;
        this.addItems(0);
      }, 200)

    } else {
      let schs = this.datasource.sch;
      if (this.schname != '') {
        this.dataview = schs.filter(p => p.sname.indexOf(this.schname) > -1 && p.zone == this.zn);
      } else {
        this.dataview = schs.filter(p => p.zone == this.zn);
      }
      setTimeout(() => {
        this.state.data = [];
        this.page = 0;
        this.addItems(0);
      }, 200)
    }
  }

  onChange(e) {
    this.zn = this.getResult(e);
    this.viewfile();
  }

  pullToRefresh(event) {
    if (event === 'endReachedRefresh') {
      if (this.page < 9) {
        this.page++;
        this.addItems(this.page * this.pageLimit);
        this.state.refreshState.currentState = 'release';
        setTimeout(() => {
          this.state.refreshState.currentState = 'finish';
        }, 1000);
      }
    } else {
      if (event === 'down') {
        this.state.data = [];
        this.page = 0;
        this.addItems(0);
      } else {
        if (this.page < 9) {
          this.page++;
          this.addItems(this.page * this.pageLimit);
        }
      }
    }
  }

  addItems(startIndex) {
    if (this.dataview.length > 0)
      if (this.pageLimit * (this.page + 1) <= this.dataview.length) {
        for (let i = startIndex; i < this.pageLimit * (this.page + 1); i++) {
          this.state.data.push(this.dataview[i]);
          if (i == this.pageLimit * (this.page + 1) - 1) {
            this._toast.hide();
          }
        }
      } else {
        setTimeout(() => {
          const toast = this._toast.info('数据已经加载完毕！', 1500, null, false);
        }, 100)
      }
  }

  async gerMojar() {
    let row = this._gms.getItems('lives_query_items')
    if (!row) {
      this.router.navigate(['/query/mojar']);
    } else {
      console.log('loadding')
      setTimeout(() => {
        const toasts = this._toast.loading('数据匹配中请稍后...', 0, () => { }, true);
      }, 100)
      let data = await this.dbs.get('v3/query/mojarview', { args: row }) as any;
      this.datasource = data;
      this.dataview = data.sch;
      this.user_result = row[2] + '、' + row[1] + '、' + row[0];
      this.addItems(0);
    }
  }

  onClick(item) {
    let data = this.datasource.data.filter(p => p.sname == item.sname)
    this._gms.setItems('lives_cp_subject_item', data);
    this.router.navigate(['/query/mojar/class/list']);
  }
}
