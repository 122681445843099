<div class="page">
    <div class="title" style="height: 44.44vw;">
        <img src="../../../../assets/new/home/index/LOGO.png" style="width: 60px;height:60px;">
    </div>
    <div class="content">
        <div class="item-box" style="margin-top: 16px;margin-bottom: 16px;">
            <!--
            <div class="issue-title">{{datasource.sc.subject_class_name}} (霍兰德职业代码：{{datasource.sc.subject_code}})</div>
            <div style="font-size: 12px;color: #999;text-indent: 2em;margin-left: 14px;margin-right: 14px;margin-top: 4px;">
                {{datasource.sc.project}}</div>
            <List style="width: 100%;" [renderHeader]="lsttitle">
                <ListItem *ngFor="let item of datasource.majors" multipleLine [extra]="lstleft" [arrow]="'horizontal'" (onClick)="onClick(item)">
                    <div style="font-size: 12px">{{item.mojar_name}}</div>
                    <Brief>
                        <div style="font-size: 11px">选科要求：{{select_type(item)}}</div>
                    </Brief>
                    <ng-template #lstleft>
                        <div style="font-size: 11px">院校信息</div>
                    </ng-template>
                </ListItem>
            </List>
            <ng-template #lsttitle>
                <div style="font-size: 12px;margin-left: 14px;margin-top: 12px;">意向匹配专业</div>
            </ng-template>
            -->
            <WhiteSpace [size]="'lg'"></WhiteSpace>
            <List style="width: 100%;" [renderHeader]="lsttitle">
                <ListItem *ngFor="let item of datasource">
                    <div style="font-size: 12px">{{item.smids}}</div>
                </ListItem>
                <ng-template #lsttitle>
                    <div style="display: flex;justify-content: space-between;margin-bottom: 12px;">
                        <div style="font-size: 13px;margin-left: 14px">我的选科结果与匹配专业</div>
                        <div style="font-size: 11px;margin-right: 28px;color: blue;">{{datasource[0].sname}}</div>
                    </div>
                </ng-template>
            </List>
        </div>
    </div>
</div>