<app-navtool [title]="'生成邀请码'" [mode]="'light'" [backurl]="'/'"></app-navtool>
<div style="height:45px"></div>
<Flex>
    <List [renderHeader]="'选择生成的邀请码类型'" style="width: 100%;">
        <RadioItemGroup [(ngModel)]="selectedStatus1.value" (onChange)="onChange($event)">
            <RadioItem *ngFor="let i of checkItemListData" [name]="i.name" [value]="i.value">
                {{ i.name }}
            </RadioItem>
        </RadioItemGroup>
    </List>
</Flex>
<WhiteSpace></WhiteSpace>
<div style="background: white;text-align: center;width: 100VW;line-height: 40px;">{{license}}</div>
<WhiteSpace></WhiteSpace>
<Flex [justify]="'center'">
    <div Button (onClick)="onClick()" class="sendbtn" [type]="'primary'">领取邀请码</div>
</Flex>
<WhiteSpace></WhiteSpace>