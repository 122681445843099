import { Component, OnInit } from '@angular/core';
import { AskthetutorService } from '../../home/askthetutor/askthetutor.service';
import { ResMessage, User, Order } from '../../resources/resources';
import { GlobalmessageService } from '../../../service/globalmessage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersService } from '../../../service/orders.service';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.css']
})
export class MyOrdersComponent implements OnInit {

  datasource: Array<Order>;
  viewsource: Array<Order>;
  user: User;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _gms: GlobalmessageService,
    private _ods: OrdersService
  ) {
    this._gms.getUserMessage()
      .subscribe(message => {
        if (message) {

        } else {
          this.user = message.data
        }
      });

  }



  ngOnInit() {
    this.user = this._gms.getUserinfo_init();
    this.getOrders();
  }


  checkTime(timestamp) {
    let timespan = new Date().getTime() / 1000;
    let m = Math.floor((timespan - timestamp) / 3600)
    return m + 'h'
  }

  dateConvert(timestamp) {
    var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    return Y + M + D + h + m + s;
  }

  getOrders() {
    this._ods.getOrders(this.user.id).then(data => {
      let obj = data as ResMessage;
      if (obj.state == 0) {
        this.datasource = obj.msg;
        this.viewsource = this.datasource.filter(p => p.status == 1);
      }
    })
  }

  showDetail(order: Order) {
    this._gms.setItems('myorder', order);
    this.router.navigate([{ outlets: { popup: 'myorderdetail' } }]);
  }

  choose(e) {
    if (e.selectedIndex == 0) {
      this.viewsource = this.datasource.filter(p => p.status == 1);
    } else if (e.selectedIndex == 1) {
      this.viewsource = this.datasource.filter(p => p.status == 2);
    } else if (e.selectedIndex == 2) {
      this.viewsource = this.datasource.filter(p => p.status == 9);
    } else if (e.selectedIndex == 3) {
      this.viewsource = this.datasource.filter(p => p.status == 5);
    }
  }
}
