import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dict-academic',
  templateUrl: './dict-academic.component.html',
  styleUrls: ['./dict-academic.component.css']
})
export class DictAcademicComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
