<app-navtool [title]="'我的资料'" [mode]="'light'" [backurl]="'/'"></app-navtool>
<div style="height: 100vh;background-color:#F0F0F0 ;">
    <div style="margin-top: 45px;"></div>
    <WhiteSpace [size]="'md'"></WhiteSpace>
    <List style="width: 100vw;">
        <ListItem [routerLink]="['../', 'myname' ]" [arrow]="'horizontal'" [extra]="user.name">
            姓名
        </ListItem>
        <ListItem [routerLink]="['../', 'mysex' ]" [arrow]="'horizontal'"  [extra]="user.sex">
            性别
        </ListItem>
        <ListItem (onClick)="onClick()" [arrow]="'horizontal'">
            年龄
        </ListItem>
        <ListItem (onClick)="onClick()" [arrow]="'horizontal'" [extra]="user.phone">
            手机号码
        </ListItem>
    </List>
    <WhiteSpace [size]="'md'"></WhiteSpace>
    <List style="width: 100vw;">
        <ListItem [arrow]="'horizontal'" (onClick)="onClick()">
            学校
        </ListItem>
        <ListItem (onClick)="onClick()" [arrow]="'horizontal'">
            年级
        </ListItem>
        <ListItem (onClick)="onClick()" [arrow]="'horizontal'">
            班级
        </ListItem>
    </List>
</div>

<ng-template #icon>
    <Icon [type]="'left'"></Icon>
</ng-template>