import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DocListComponent } from './doc-list/doc-list.component';
import { DocDetailComponent } from './doc-detail/doc-detail.component';

const routes: Routes = [
  { path: '', component: DocListComponent },
  { path: 'item', component: DocDetailComponent },
  { path: 'list', component: DocListComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocRoutingModule { }
