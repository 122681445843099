import { Component, OnInit, Input } from '@angular/core';
import { Major, SchoolBatch, SelectMajor, SubjectEnrollment } from '../major';

@Component({
  selector: 'app-major-detail',
  templateUrl: './major-detail.component.html',
  styleUrls: ['./major-detail.component.css']
})
export class MajorDetailComponent implements OnInit {

  @Input() m: Major;
  @Input() sb: SchoolBatch;
  @Input() sm: SelectMajor;
  @Input() se: SubjectEnrollment;
  constructor() { }

  ngOnInit() {
    console.log(this.m)
    if (this.sm == null) {
      this.sm = new SelectMajor()
    }
    if (this.sb == null) {
      this.sb = new SchoolBatch();
    }
    if (this.m == null) {
      this.m = new Major();
    }
    if (this.se == null) {
      this.se = new SubjectEnrollment();
    }
    for (let i in this.m) {
      if (this.m[i] == null) {
        this.m[i] = '';
      }
    }
    for (let i in this.sb) {
      if (this.sb[i] == null) {
        this.sb[i] = '';
      }
    }
    for (let i in this.sm) {
      if (this.sm[i] == null) {
        this.sm[i] = '';
      }
    }
    for (let i in this.se) {
      if (this.se[i] == null) {
        this.se[i] = '';
      }
    }
  }

  onSelectItems(num: number, type: number) {
    if (num == 0) {
      if (type == 1) {
        return '没有要求';
      } else {
        return '不可选';
      }
    } else {
      if (type == 0) {
        return '没有要求';
      } else if (type == 1) {
        return '可选';
      } else {
        return '必选';
      }
    }
  }

  onShow(num: number) {
    if (num == 1) {
      if (this.sm == null) {
        return true;
      } else {
        return false;
      }
    }
  }

}
