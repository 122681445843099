import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OutMessage, User, ResMessage } from '../../resources/resources';
import { GlobalmessageService } from 'src/app/service/globalmessage.service';
import { MyService } from '../my.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService, ModalRef } from 'ng-zorro-antd-mobile';
import { ToastService } from 'ng-zorro-antd-mobile';

@Component({
  selector: 'app-my-register',
  templateUrl: './my-register.component.html',
  styleUrls: ['./my-register.component.css']
})
export class MyRegisterComponent implements OnInit {

  user: User;
  disabledStatus: boolean = true;
  error: boolean = false;
  phone: number;
  password: string;
  nextpassword: string;
  errmsginfo: string = '';
  name: string = "";

  errorpw: boolean = false;
  errmsginfopw: string = '';
  errorpw2: boolean = false;
  errmsginfopw2: string = '';
  agreeItemData = { value: 'Agree Submit', name: 'Agree Item', checked: false };

  constructor(
    private _gms: GlobalmessageService,
    private _mys: MyService,
    private router: Router,
    private route: ActivatedRoute,
    private _toast: ToastService,
    private _ms: ModalService
  ) {

  }

  ngOnInit() {

  }


  errmsg(type) {
    if (type == 1)
      this._toast.info(this.errmsginfo);
    else if (type == 2)
      this._toast.info(this.errmsginfopw);
    else if (type == 3)
      this._toast.info(this.errmsginfopw2);
  }

  checkPhone() {
    if (this.phone.toString().length != 11) {
      this.errmsginfo = '请输入11位手机号码';
      this._toast.info('请输入11位手机号码');
      this.error = true;
    } else {
      this._mys.checkPhone(this.phone).then(data => {
        let tmp = data as ResMessage;
        let t = tmp.msg[0].ps;
        if (t == 1) {
          this.error = true;
          this.errmsginfo = '手机号码已被注册';
          this._toast.info('手机号码已被注册');
        } else
          this.error = false;
      })
    }
  }

  inputChange(e, type) {
    if (type == 'phone') {
      this.phone = e.replace(/\s/g, '');
    } else if (type == 'password') {
      this.password = e;
      if (this.password.length < 6) {
        this.errorpw = true;
        this.errmsginfopw = '密码长度不能少于6位';
      } else {
        this.errorpw = false;
      }
    } else if (type == 'nextpassword') {
      this.nextpassword = e;
      if (this.password.length < 6) {
        this.errorpw = true;
        this.errmsginfopw = '密码长度不能少于6位';
      } else {
        if (this.nextpassword != this.password) {
          this.errorpw2 = true;
          this.errmsginfopw2 = '密码输入不一致';
        } else {
          this.errorpw2 = false;
        }
      }
    } else if (type == 'name') {
      this.name = e;
    }
  }

  onSave() {
    if (!this.agreeItemData.checked) {
      this._toast.info('请先同意用户协议');
      return;
    }
    if (this.name.length <= 0) {
      this._toast.info('姓名不能为空');
      return;
    }
    if (!this.errorpw2 && !this.errorpw && !this.error) {
      let tmpuser = new User;
      tmpuser.name = this.name;
      tmpuser.phone = this.phone.toString();
      tmpuser.password = this.password;
      this._mys.regUser(tmpuser).then(data => {
        let msg = data as ResMessage;
        if (msg.state == 0) {
          this.onLogin(tmpuser);
        }
      })
    }
  }

  onLogin(user: User) {
    this._mys.login('user').then(res => {
      let tmp=res as ResMessage;
      this._gms.setUserinfo(tmp.msg as User);
      this._gms.setLoginStatus(true);
      this.onLeftClick();
    })
  }

  onClick(e) {
    e.stopPropagation();
    e.preventDefault();
    this._ms.alert('用户协议', '... ...')
  }

  onLeftClick() {
    this.router.navigate(['/']);
  }
}
