import { Injectable } from '@angular/core';
import { ResMessage } from '../pages/resources/resources';
import { DbsService } from './dbs.service';

@Injectable({
  providedIn: 'root'
})
export class PayService {

  constructor(
    private dbs: DbsService
  ) { }
  
  async getPaySign(name, openid, total_fee) {
    let args = { body: name, openid: openid, total_fee: total_fee }
    let msg = await this.dbs.get('pay/pay', args) as ResMessage
    return new Promise(resolve => {
      if (msg.state == 0) {
        (window as any).WeixinJSBridge.invoke(
          'getBrandWCPayRequest', msg.msg,
          async function (res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              resolve({ code: 1, message: '支付成功' })
            } else {
              resolve({ code: 2, message: '支付失败' })
            }
          });
      }else{
        resolve({ code: 2, message: '调用支付失败' })
      }
    })

  }
}
