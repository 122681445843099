import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HomeModuleModule } from './pages/home/home.module';
import { MarjorsModule } from './pages/marjors/marjors.module';
import { DocModule } from './pages/doc/doc.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgZorroAntdMobileModule } from 'ng-zorro-antd-mobile';
import { MyComponent } from './pages/my/my.component';
import { HomeComponent } from './pages/home/home.component';
import { SubjectModule } from './pages/home/ranking/subject/subject.module';
import { MyDetailComponent } from './pages/my/my-detail/my-detail.component';
import { ViewsComponent } from './views/views.component';
import { MyImageComponent } from './pages/my/my-image/my-image.component';
import { NavtoolComponent } from './navtool/navtool.component';
import { MyNameComponent } from './pages/my/my-name/my-name.component';
import { MarjorsComponent } from './pages/marjors/marjors.component';
import { AngularWebStorageModule } from 'angular-web-storage';
import { MyLoginComponent } from './pages/my/my-login/my-login.component';
import { MySexComponent } from './pages/my/my-sex/my-sex.component';
import { MySelectSubjectComponent } from './pages/my/my-select-subject/my-select-subject.component';
import { MyAuthorizeComponent } from './pages/my/my-authorize/my-authorize.component';
import { MyRegisterComponent } from './pages/my/my-register/my-register.component';
import { AppWaitingComponent } from './pages/app-waiting/app-waiting.component';
import { MyWxAuthComponent } from './pages/my/my-wx-auth/my-wx-auth.component';
import { PayMessageComponent } from './pages/pay-message/pay-message.component';
import { MyConsultComponent } from './pages/my/my-consult/my-consult.component';
import { MyConsultListComponent } from './pages/my/my-consult-list/my-consult-list.component';
import { MyConsultDetailComponent } from './pages/my/my-consult-detail/my-consult-detail.component';
import { ShopComponent } from './pages/shop/shop.component';
import { MyOrdersComponent } from './pages/my/my-orders/my-orders.component';
import { ShopDetailComponent } from './pages/shop/shop-detail/shop-detail.component';
import { SyghComponent } from './pages/shop/shop-detail/sygh/sygh.component';
import { ShopChecksComponent } from './pages/shop/shop-checks/shop-checks.component';
import { MyOrdersListComponent } from './pages/my/my-orders/my-orders-list/my-orders-list.component';
import { MyOrdersDetailComponent } from './pages/my/my-orders/my-orders-detail/my-orders-detail.component';
import { MyAboutComponent } from './pages/my/my-about/my-about.component';
import { DeliverComponent } from './pages/my/my-orders/deliver/deliver.component';
import { RefundComponent } from './pages/my/my-orders/refund/refund.component';
import { DictAcademicComponent } from './pages/manager/dict-academic/dict-academic.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgxFloatBallModule } from 'ngx-float-ball';
import { UserinfoComponent } from './pages/userinfo/userinfo.component';
import { QueryMojarComponent } from './pages/query/query-mojar/query-mojar.component';
import { QueryMojarClassComponent } from './pages/query/query-mojar/query-mojar-class/query-mojar-class.component';
import { QueryMojarListComponent } from './pages/query/query-mojar/query-mojar-list/query-mojar-list.component';
import { MaterialModule } from './material-module';
import { QueryMojarSchoolComponent } from './pages/query/query-mojar/query-mojar-school/query-mojar-school.component';
import { EvaResultComponent } from './pages/userinfo/eva-result/eva-result.component';

@NgModule({
  declarations: [
    AppComponent,
    MyComponent,
    HomeComponent,
    MyDetailComponent,
    ViewsComponent,
    MyImageComponent,
    NavtoolComponent,
    MyNameComponent,
    MarjorsComponent,
    MyLoginComponent,
    MySexComponent,
    MySelectSubjectComponent,
    MyAuthorizeComponent,
    MyRegisterComponent,
    AppWaitingComponent,
    MyWxAuthComponent,
    PayMessageComponent,
    MyConsultComponent,
    MyConsultListComponent,
    MyConsultDetailComponent,
    ShopComponent,
    MyOrdersComponent,
    ShopDetailComponent,
    SyghComponent,
    ShopChecksComponent,
    MyOrdersListComponent,
    MyOrdersDetailComponent,
    MyAboutComponent,
    DeliverComponent,
    RefundComponent,
    DictAcademicComponent,
    UserinfoComponent,
    QueryMojarComponent,
    QueryMojarClassComponent,
    QueryMojarListComponent,
    QueryMojarSchoolComponent,
    EvaResultComponent
  ],
  imports: [
    BrowserModule, FormsModule, HttpClientModule, ReactiveFormsModule, NgZorroAntdMobileModule, MaterialModule,
    BrowserAnimationsModule, AppRoutingModule, AngularWebStorageModule, DocModule, NgxFloatBallModule, MatIconModule, MatButtonModule
  ],
  providers: [AppComponent, NavtoolComponent, HomeComponent,
    //{ provide: HTTP_INTERCEPTORS, useClass: DbsinterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
