import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResMessage } from 'src/app/pages/resources/resources';
import { DbsService } from 'src/app/service/dbs.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { GlobalmessageService } from 'src/app/service/globalmessage.service';

@Component({
  selector: 'app-query-mojar-list',
  templateUrl: './query-mojar-list.component.html',
  styleUrls: ['./query-mojar-list.component.css']
})
export class QueryMojarListComponent implements OnInit {

  datasource: any = [];
  
  constructor(
    private _gms: GlobalmessageService,
    private router: Router
  ) { }

  ngOnInit() {
    this.datasource = this._gms.getItems('lives_cp_subject_item');
  }

  select_type(item) {
    let count_select = item.wl + item.hx + item.sw + item.ls + item.zz + item.dl;
    if (count_select == 12) {
      return '无';
    } else if (count_select == 1 || count_select == 2) {
      //item.keys
      let lab = this.select_lable(item);
      return lab.toString()
    }
  }

  onClick(item) {
    this._gms.setItems('lives_cp_mojar_item', item);
    let path = '/query/mojar/class/list/school'
    this.router.navigate([path]);
  }

  select_lable(type) {
    let str = [];
    if (type.wl == 1) {
      str.push('物理')
    }
    if (type.hx == 1) {
      str.push('化学')
    }
    if (type.sw == 1) {
      str.push('生物')
    }
    if (type.ls == 1) {
      str.push('历史')
    }
    if (type.zz == 1) {
      str.push('政治')
    }
    if (type.dl == 1) {
      str.push('地理')
    }
    return str;
  }

}
