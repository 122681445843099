import { Injectable } from '@angular/core';
import { DbsService } from './dbs.service';
import { Order } from '../pages/resources/resources';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(
    private dbs: DbsService
  ) { }

  async newOrder(order: Order) {
    let result = await this.dbs.get('order/add', order);
    console.log(result)
    return result;
  }
  async updateIfPay(oid: number) {
    let args = { oid: oid }
    let result = await this.dbs.get('order/update/status/pay', args);
    return result;
  }

  async updateStatusDelivery(oid: number, delivery: string, expressnumber: string) {
    let args = { oid: oid, delivery: delivery, expressnumber: expressnumber };
    let result = await this.dbs.get('order/update/status/delivery', args)
    return result;
  }

  async getOrders(uid: number) {
    let args = { id: uid }
    let result = await this.dbs.get('order/getOrders', args)
    return result;
  }
}
