import { Component, OnInit, Input } from '@angular/core';
import { GlobalmessageService } from 'src/app/service/globalmessage.service';
import { MajorCheckEnrollment } from '../major';

@Component({
  selector: 'app-major-item',
  templateUrl: './major-item.component.html',
  styleUrls: ['./major-item.component.css']
})
export class MajorItemComponent implements OnInit {


  @Input() mce: MajorCheckEnrollment;
  thumbStyle = {
    width: '32px',
    height: '32px'
  };
  constructor(

  ) { }

  ngOnInit() {

  }

}
