<div class="page" style="height: 100vh;">
    <div class="title" style="height: 44.44vw;">
        <img src="../../../../assets/new/home/index/LOGO.png" style="width: 60px;height:60px;">
    </div>
    <div class="content">
        <div class="item-box" style="margin-top: 16px;margin-bottom: 16px;line-height:18px">
            <WhiteSpace [size]="'lg'"></WhiteSpace>
            <div style="text-indent: 2em;font-size: 13px;color:#777;padding-left: 16px;padding-right: 16px;">
                中学阶段是人生的黄金时代，也是人生旅途的重要转折点。学涯？职涯？生涯？对于当下的你，或许觉得太遥远、太抽象。
                但如果问你有什么梦想和愿望、将来希望从事什么职业，或多或少你会有想法。【春芽计划】多年来依托国内生涯规划优秀专家资源，
                积极构建中学生生涯规划一体化解决方案，为学生搭建起一道与未来衔接的生涯桥梁并守护他们与理想茁壮成长！
            </div>
            <div style="text-indent: 2em;font-size: 11px;color:#999;padding-left: 16px;padding-right: 16px;margin-bottom: 24px;">
                注：【春芽计划】系“众智生涯”旗下生涯规划一体化解决方案核心品牌
            </div>
        </div>
    </div>
</div>