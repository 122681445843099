import { Component, OnInit, Input } from '@angular/core';
import { ModalService, ModalRef } from 'ng-zorro-antd-mobile';
import { MyService } from '../my.service';
import { ToastService } from 'ng-zorro-antd-mobile';
import { GlobalmessageService } from '../../../service/globalmessage.service';
import { User, ResMessage, WxUserinfo } from '../../resources/resources';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/service/user.service';


@Component({
  selector: 'app-my-login',
  templateUrl: './my-login.component.html',
  styleUrls: ['./my-login.component.css']
})
export class MyLoginComponent implements OnInit {
  path:string='';
  constructor(
    private _gms: GlobalmessageService
  ) {

  }

  async ngOnInit() {
    this.path=window.location.host;
  }


  onWxAuth() {
    let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=';
    url += 'wx6504e4b07e3bf7c9';
    url += '&redirect_uri=';
    if(this.path.indexOf('www')>-1)
      url += encodeURIComponent('https://www.zzsygh.com/mywxauth');
    else
      url += encodeURIComponent('http://experiment.zzsygh.com/mywxauth');
    url += '&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect'
    this._gms.setWxStatus(true);
    window.location.href = url;
  }
}
