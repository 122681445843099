<List style="width: 100vw;margin-top: 18px;">
    <ListItem [arrow]="'horizontal'" [routerLink]="[{ outlets: { popup: ['myorder'] } }]">
        学科
    </ListItem>
</List>
<List style="width: 100vw;margin-top: 12px;">
    <ListItem [arrow]="'horizontal'" [routerLink]="[{ outlets: { popup: ['myorder'] } }]">
        学科类
    </ListItem>
</List>
<div class="am-list" style="margin:0;">
    <div class="am-list-header">培养目标:</div>
    <div class="am-list-body">
        <TextareaItem [rows]="5" [count]="1000" [defaultValue]="'计数功能,我的意见是...'">手机号码</TextareaItem>
    </div>
</div>
<div class="am-list" style="margin:0;">
    <div class="am-list-header">培养目标:</div>
    <div class="am-list-body">
        <TextareaItem [rows]="5" [count]="1000" [defaultValue]="'计数功能,我的意见是...'">手机号码</TextareaItem>
    </div>
</div>
<div class="am-list" style="margin:0;">
    <div class="am-list-header">培养目标:</div>
    <div class="am-list-body">
        <TextareaItem [rows]="5" [count]="1000" [defaultValue]="'计数功能,我的意见是...'">手机号码</TextareaItem>
    </div>
</div>