import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DocRoutingModule } from './doc-routing.module';
import { DocListComponent } from './doc-list/doc-list.component';
import { DocDetailComponent } from './doc-detail/doc-detail.component';
import { NgZorroAntdMobileModule } from 'ng-zorro-antd-mobile';
import {SafeHtmlPipe} from './safe-html.pipe'


@NgModule({
  declarations: [DocListComponent, DocDetailComponent,SafeHtmlPipe],
  imports: [
    CommonModule,
    DocRoutingModule,
    NgZorroAntdMobileModule,
  ]
})
export class DocModule { }
