import { Injectable } from '@angular/core';
import { DbsService } from '../../service/dbs.service';
import { User, ResMessage } from '../../pages/resources/resources';
import { UserSubject } from './my';
import { GlobalmessageService } from '../../service/globalmessage.service'
import { UserService } from 'src/app/service/user.service';
@Injectable({
  providedIn: 'root'
})
export class MyService {
  user: User;
  constructor(
    private _dbs: DbsService,
    private _gms: GlobalmessageService,
    private _us: UserService
  ) {
    this.user = this._gms.getUserinfo_init();
    if (Object.keys(this.user).length > 0) {
      this._dbs.set(this.user.token)
    }
  }

  async livesKey() {
    let result = await this._dbs.get('auth/key');
    return result;
  }

  async login(openid: string) {
    let args = { openid: openid }
    let result = await this._dbs.get('users/login', args)
    return result;
  }

  async updateUserInfoName(user: User) {
    let result = await this._dbs.get('users/myname', user)
    return result;
  }

  async updateUserInfoSex(user: User) {
    let result = await this._dbs.get('users/mysex', user)
    return result;
  }

  async updateUserSubject(us: UserSubject) {
    let result = await this._dbs.get('users/my/user/subject/update', us)
    return result;
  }

  async getUserSubject() {
    let result = await this._dbs.get('user/get/subject')
    return result;
  }

  async setUserAuthorize(license: string) {
    let obj = { license: license }
    let result = await this._dbs.get('users/my/user/setauthorize', obj)
    return result;
  }

  async getUserAuthorize(type: number) {
    let obj = { type: type }
    let result = await this._dbs.get('users/my/user/authorize', obj)
    return result;
  }

  async regUser(user: User) {
    let result = await this._dbs.get('users/my/user/reg', user)
    return result;
  }

  async checkUserAuthorize() {
    let result = await this._dbs.get('cp/get/authorize')
    return result;
  }


  async checkPhone(phone: number) {
    let obj = { phone: phone };
    let result = await this._dbs.get('users/my/user/checkuser', obj)
    return result;
  }

  async getOpenid(code) {
    let flowid = this._gms.getItems('lives_flowid');
    let agrs = { code: code, flowid: flowid };
    let that = this;
    let result: any;
    result = await this._dbs.get('auth/login', agrs)
    if (result) {
      that._gms.set_lives_user_token(result.token)
      that._gms.setUserinfo(result.user)
    }
    return result;
  }

  async getEmsCode(phone) {
    let agrs = { phone: phone };
    let result = await this._dbs.get('ems/sendcheck', agrs)
    return result;
  }

}
