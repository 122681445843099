import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Subject } from 'rxjs';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { OutMessage, User, MajorSelect } from '../pages/resources/resources';
import { UserSubject } from '../pages/my/my';
import { MajorSelectValues, MajorCheckEnrollment } from '../pages/marjors/major';
import { Cp, CpItems, CpAnwMessage, CpAnwList, CpAnw } from '../pages/home/cp/cp';

@Injectable({
  providedIn: 'root'
})
export class GlobalStorageService {

  constructor(
    public local: LocalStorageService,
    public session: SessionStorageService
  ) { }

  setAppinfo(appinfo: any) {
    this.session.set('appinfo', appinfo);
  }

  getAppinfo() {
    let appinfo = this.session.get('appinfo');
    return appinfo;
  }

  setResource(resource: MajorSelect) {
    this.session.set('resource', resource);
  }

  getResource(): MajorSelect {
    let resource = this.session.get('resource');
    return resource;
  }


}
