export class MajorCheckEnrollment {
    id: number;
    sid: number;
    mid: number;
    name: string;
    zone: string;
    lowest: number;
    year: number;
    subjectname: string;
    batch: string;
    subject: string;
}

export class Major {
    id: number;
    name: string;
    target: string;
    requirement: string;
    curriculum: string;
}

export class SchoolBatch {
    sid: string;
    year: string;
    batch: string;
    subject: string;
    name: string;
    zone: string;
    s985: string;
    s211: string;
    plan: number;
    files: number;
    enrollment: number;
    peak: number;
    average: number;
    lowest: number;
}

export class MajorSelectValues {
    school: string;
    zone: string;
    batch: string;
    subject: string;
    major: string;
    minnum: number;
    maxnum: number;
    year: string;
    type: number;
}

export class SelectMajor {
    id: string;
    zone: string;
    sname: string;
    sid: string;
    arrangement: string;
    mid: number;
    major: number;
    requirement: string;
    contain: string;
    wl: number;
    hx: number;
    sw: number;
    ls: number;
    zz: number;
    dl: number;
    type: number;
}

export class SubjectEnrollment {
    id: number;
    sid: number;
    sname: string;
    mid: number;
    subjectid: string;
    subjectname: string;
    plan: number;
    enrollment: number;
    peak: number;
    average: number;
    lowest: number;
    year: number;
    batch: string;
    subject: string;
}


export class MajorDetail {
    m: Major;
    sm: SelectMajor;
    sb: SchoolBatch;
    se: SubjectEnrollment;
}