import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalmessageService } from '../../../service/globalmessage.service';

@Component({
  selector: 'app-my-wx-auth',
  templateUrl: './my-wx-auth.component.html',
  styleUrls: ['./my-wx-auth.component.css']
})
export class MyWxAuthComponent implements OnInit {

  message:string='系统登录中，请稍后';
  constructor(
    private router: Router,
    private _gms: GlobalmessageService,
  ) { }

  ngOnInit() {
    let count_num = 1;
    let ti = setInterval(() => {
      let user = this._gms.getUserinfo_init();
      let boo = Object.keys(user).length > 0;
      if (boo) {
        let url = this._gms.getItems('app_back_url')
        if (url != null) {
          clearInterval(ti);
          this.router.navigate([url]);
        }
        else {
          clearInterval(ti);
          this.router.navigate(['/home']);
        }
      } else {
        count_num = count_num + 1;
        if(count_num==5){
          clearInterval(ti);
          this.message="系统登录失败，请重新登录！";
          this.router.navigate(['/home']);
        }
      }
    }, 300)
  }

}
