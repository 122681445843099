export class Ask {
    id: number;
    uid: number;
    oid: number;
    message: string;
    createtime: number;
    replytime: number;
    ifread: boolean;
    teaifread: boolean;
    sendreply: boolean;
    flag: number;
    deleteflag: boolean;
    replymessage: string;
    senduser: boolean;
    ywz: number;
    ywj: number;
    wlz: number;
    wlj: number;
    sxz: number;
    sxj: number;
    yyz: number;
    yyj: number;
    lsz: number;
    lsj: number;
    dlz: number;
    dlj: number;
    zzz: number;
    zzj: number;
    hxz: number;
    hxj: number;
    swz: number;
    swj: number;
    city:string;
    grade:string;
    name:string;
    phone:string;
}