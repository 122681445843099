<ng-template #icon>
        <Icon [type]="'left'"></Icon>
</ng-template>
<Navbar [icon]="icon"  style="background-color: #4B8D7F;" [rightContent]="rightContent" (onLeftClick)="onLeftClick()">
        性别
</Navbar>
<ng-template #rightContent>
        <div (click)="onSave()">保存</div>
</ng-template>
<WhiteSpace [size]="'md'"></WhiteSpace>
<List className="my-list">
        <ListItem [extra]="sex" [arrow]="'horizontal'" (click)="showSexPicker()">
                性别
        </ListItem>
</List>