<WhiteSpace size='md'></WhiteSpace>
<WingBlank [size]="'lg'">
    <Card>
        <CardHeader [title]="mce.subjectname" [extra]="mce.name" [thumbStyle]="thumbStyle">
        </CardHeader>
        <CardBody>
            <div>
                <Flex [wrap]="'wrap'">
                    <div style="width: 33.33%;">批次：{{mce.batch}}</div>
                    <div style="width: 66.66%;">高校所在城市：{{mce.zone}}</div>
                    <div style="width: 33.33%;">文理：{{mce.subject}}</div>
                    <div style="width: 66.66%;">{{mce.year}}年最低录取分数：<span style="color: green;">{{mce.lowest}}</span></div>
                </Flex>
            </div>
        </CardBody>
    </Card>
</WingBlank>