import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionSheetService, ToastService } from 'ng-zorro-antd-mobile';
import { en_US, ru_RU, zh_CN, sv_SE, da_DK } from 'ng-zorro-antd-mobile';
import { OutMessage, User } from '../../resources/resources';
import { GlobalmessageService } from '../../../service/globalmessage.service';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-my-detail',
  templateUrl: './my-detail.component.html',
  styleUrls: ['./my-detail.component.css']
})
export class MyDetailComponent implements OnInit {

  user: User;
  dataList = [
    { url: 'OpHiXAcYzmPQHcdlLFrc', title: '发送给朋友' },
    { url: 'wvEzCMiDZjthhAOcwTOu', title: '新浪微博' },
    { url: 'cTTayShKtEIdQVEMuiWt', title: '生活圈' },
    { url: 'umnHwvEgSyQtXlZjNJTt', title: '微信好友' },
    { url: 'SxpunpETIwdxNjcJamwB', title: 'QQ' }
  ].map(obj => ({
    icon: `<img src="https://gw.alipayobjects.com/zos/rmsportal/${obj.url}.png" style="width:36px"/>`,
    title: obj.title
  }));

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _actionSheet: ActionSheetService,
    private _toast: ToastService,
    private _gms: GlobalmessageService,
    private _us:UserService
  ) {
    this._gms.receiveAppMessage().subscribe(msg => {
      if (msg != null) {
        if (msg.module == 'app_user') {
          this.user = msg.data as User;
        }
      }
    });
      
  }
  async ngOnInit() {
     await this._us.getUser();
  }

  onLeftClick() {

  }

  onClick() {
    //this.showShareActionSheet();
  }

  showShareActionSheet = () => {
    this._actionSheet.showShareActionSheetWithOptions(
      {
        options: this.dataList,
        message: 'I am description, description, description',
        locale: zh_CN
      },
      buttonIndex => {
        return new Promise(resolve => {
          this._toast.info('closed after 1000ms');
          setTimeout(resolve, 1000);
        });
      }
    );
  }

}
