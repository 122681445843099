import { Injectable } from '@angular/core';
import { GlobalmessageService } from './globalmessage.service';
import { DbsService } from './dbs.service';
import { User, ResMessage } from '../pages/resources/resources';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  require: any

  constructor(private _gms: GlobalmessageService, private _dbs: DbsService, private router: Router) { }

  async getUser() {
    let token = await this._gms.get_lives_user_token();
    let data = await this.getUserInfo() as ResMessage;
    try {
      if (data.state == 0) {
        this._gms.sendAppMessage({ module: 'app_user', data: data.msg });
        this._gms.sendAppMessage({ module: 'app_login', data: true });
      } else {
        this._gms.sendAppMessage({ module: 'app_login', data: false });
      }
    } catch {
      this._gms.sendAppMessage({ module: 'app_login', data: false });
    }
  }

  async getUserInfo() {
    let resut = await this._dbs.get('auth/userinfo');
    return resut;
  }

  async getUserResult() {
    let result = await this._dbs.get('user/get/result');
    return result;
  }

  async checkCpToll() {
    let result = await this._dbs.get('v3/cp/check/toll');
    return result;
  }

  async checkCptollNa(cb) {
    let result = await this._dbs.get('v3/cp/check/toll');
    return cb(result);
  }

  async useVipno(vipno) {
    let result = await this._dbs.get('v3/user/usevipno', { vipno: vipno });
    return result;
  }

  async updateflow(flowid){
    let result = await this._dbs.get('lives/flow/update', { flowid: flowid });
    return result;
  }

  async getEva(){
    let result = await this._dbs.get('v3/cp/eva/getresult');
    return result;
  }
}
