<div style="height: 100vh;width: 100vw;background-color: white;">
    <div
        style="padding-top: 12px;padding-left: 24px;padding-right: 24px;padding-bottom: 24px;display: flex;justify-content: center;">
        <div style="width:100%;display: flex;flex-direction: column;justify-content: space-between;" *ngFor="let item of datasource" (click)="onRead(item)">
            <div class="content">
                <div class="item-box" style="margin-top: 16px;margin-bottom: 16px;flex: 100%;">
                    <WhiteSpace [size]="'lg'"></WhiteSpace>
                    <img src="{{item.images}}" class="img" />
                    <Flex [align]="'start'" style="margin-top: 4px;height: 20px;">
                        <p class="text">
                            {{item.title}}
                        </p>
                    </Flex>
                    <Flex [align]="'start'" style="margin-top: 4px;margin-bottom:16px;height: 20px;">
                        <p class="t2">
                            {{item.title2}}
                        </p>
                    </Flex>                    
                </div>
            </div>
        </div>
    </div>
</div>