import { Component, OnInit } from '@angular/core';
import { User, ResMessage, Order } from '../../resources/resources';
import { GlobalmessageService } from '../../../service/globalmessage.service';
import { OrdersService } from '../../../service/orders.service';
import { PayService } from '../../../service/pay.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shop-checks',
  templateUrl: './shop-checks.component.html',
  styleUrls: ['./shop-checks.component.css']
})
export class ShopChecksComponent implements OnInit {
  order = new Order();
  free_number: number = 49.80;
  value: number = 1;
  isErrorAddres: boolean = false;
  isErrorPhone: boolean = false;
  isErrorName: boolean = false;
  showlogin: boolean;
  user: User;
  onFocusAddress: object = {
    focus: false
  };

  onFocusPhone: object = {
    focus: false
  };
  onFocusName: object = {
    focus: false
  };

  constructor(private _pay: PayService,
    private _ods: OrdersService,
    private _gms: GlobalmessageService,
    private router: Router,
    private _pays: PayService
  ) {
    this.showlogin = this._gms.getLoginStatus_init();
    this.user = this._gms.getUserinfo_init();
  }

  ngOnInit() {
    if (!this.showlogin) {
      this._gms.setViewIndex(3);
      this.router.navigate(['/']);
      //跳转登录
    } else {
      this.order.uid = this.user.id;
      this.order.goodid = 2;
      this.order.phone = this.user.phone;
      this.order.name = this.user.nickname;
      this.order.orderamount = 49.8;
      this.order.goodnum = 1;
      this.order.goodfree = 49.8;
      this.order.goodname = '中学《生涯规划》教材';
    }
  }

  inputErrorClick(e, type) {
    if (type == 'address')
      alert('收件地址不正确，请重新输入。');
  }

  inputChange(e, type) {
    if (type == 'address') {
      if (e.replace(/\s/g, '').length < 5 && e.replace(/\s/g, '').length > 0) {
        this.isErrorAddres = true;
      } else {
        if (e.length < 6) {
          this.isErrorAddres = true;
        } else {
          this.order.address = e;
          this.isErrorAddres = false;
        }
      }
    } else if (type == 'phone') {
      if (e.replace(/\s/g, '').length < 5 && e.replace(/\s/g, '').length > 0) {
        this.isErrorPhone = true;
      } else {
        if (e.length != 11) {
          this.isErrorPhone = true;
        } else {
          this.order.phone = e;
          this.isErrorPhone = false;
        }
      }
    } else if (type == 'name') {
      if (e.replace(/\s/g, '').length < 5 && e.replace(/\s/g, '').length > 0) {
        this.isErrorName = true;
      } else {
        if (e.length < 2) {
          this.isErrorName = true;
        } else {
          this.order.name = e;
          this.isErrorName = false;
        }
      }
    }

  }

  setpperChange($event) {
    this.free_number = $event * 49.8;
    this.order.goodnum = $event;
    this.order.orderamount = this.free_number;
  }

  onSubmitOrder() {
    if (this.order.address.length > 0 && this.order.name.length > 0 && this.order.name.length > 0) {
      this._ods.newOrder(this.order).then(data => {
        let msg = data as ResMessage;
        if (msg.state == 0) {
          this._pays.getPaySign('学业规划', this.user.openid, this.order.orderamount).then(res => {
            let rs = res as any;
            //this._toast.info(JSON.stringify(msg.message))
            if (rs.code == 1) {
              this._ods.updateIfPay(msg.msg);
              alert('支付成功!');
              this.router.navigate(['/']);
            }
          })
        }
      })
    }
  }

}
