<router-outlet></router-outlet>
<ngx-float-ball *ngIf="showview" (clicked)="onViewMenu()" [isBlinked]="true" [outerCircleDiameter]="50"
    [background]="'#2EBAD1'" [foreground]="'#2EBAD1'" [hasRipple]="true" [initPos]="'[100,0]'"
    [icon]="'assets/logo1.png'">
</ngx-float-ball>
<div *ngIf="!showview" style="position: fixed;bottom: 75px;left: 50px;right: 24px;z-index:9999">
    <div class="item-box" style="color: #999;height: 60px;">
        <div class="example-button-row">
            <div class="example-flex-container">
                <div class="example-button-container">
                    <button mat-mini-fab style="background-color:#2EBAD1 ;" (click)="onGohome()"
                        aria-label="Example icon button with a menu icon">
                        <mat-icon>home</mat-icon>
                    </button>
                </div>
                <div class="example-button-container">
                    <button mat-mini-fab style="background-color:#2EBAD1;" (click)="onGomy()"
                        aria-label="Example icon button with a plus one icon">
                        <mat-icon>face</mat-icon>
                    </button>
                </div>
                <div class="example-button-container">
                    <button mat-mini-fab style="background-color:#2EBAD1 ;" (click)="onViewMenu()"
                        aria-label="Example icon button with a filter list icon">
                        <mat-icon>filter_list</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>