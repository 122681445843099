<ng-template #icon>
  <Icon [type]="'left'"></Icon>
</ng-template>
<Navbar [icon]="icon"  style="background-color: #4B8D7F;" [rightContent]="rightContent" (onLeftClick)="onLeftClick()">
  我的选科
</Navbar>
<ng-template #rightContent>
  <div (click)="onSave()">保存</div>
</ng-template>
<List [renderHeader]="'请选择三科'">
  <CheckboxItem *ngFor="let i of checkItemListData" [name]="i.name" [value]="i.value" [(ngModel)]="i.checked"
    (onChange)="onChange($event)">
    {{ i.name }}
  </CheckboxItem>
</List>