import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourceService } from './service/resource.service';
import { GlobalmessageService } from './service/globalmessage.service';
import { GlobalStorageService } from './service/global-storage.service'
import { MajorSelect, ResMessage } from './pages/resources/resources';
import { UserService } from './service/user.service';
import { MyService } from './pages/my/my.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  showview: boolean = true;
  message: string = '';
  showlogin: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _rss: ResourceService,
    private _gms: GlobalmessageService,
    private _gss: GlobalStorageService,
    private _us: UserService,
    private _ms: MyService
  ) {
    this._gms.receiveMessage()
      .subscribe(message => this.message = message);
  }

  async appInit() {
    this._rss.getAppInfo().then(data => {
      this._gss.setAppinfo(data)
    });
    this._rss.getMajorResource().then(data => {
      let tmp = data as MajorSelect
      this._gss.setResource(tmp)
    })

  }

  onViewMenu() {
    this.showview = !this.showview;
  }

  onGohome() {
    this.showview = true;
    this.router.navigate(['/home']);
  }

  onGomy() {
    this.showview = true;
    this.router.navigate(['/userinfo']);
  }

  async ngOnInit() {
    this.route.queryParams.subscribe(async p => {
      let flowid = p.flowid;
      if (flowid != null) {
        this._gms.setItems('lives_flowid', flowid)
        await this._us.updateflow(flowid);
      }else{
        await this._us.updateflow(10000);
      }
    });

    this.appInit();
    let wxstatus = this._gms.getWxstatus()
    if (wxstatus) {
      let code = '';
      this.route.queryParams.subscribe(async p => {
        code = p.code;
        if (code != null) {
          let us = await this._ms.getOpenid(code);
          this._gms.setWxCode(code);
        }
      });
    }



    await this._us.getUser();
  }
}
