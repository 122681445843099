<div class="mainview">
    <div class="title" style="height: 44.44vw;">
        <img src="../../../../assets/new/home/index/LOGO.png" style="width: 60px;height:60px;">
    </div>
    <WhiteSpace size='md'></WhiteSpace>
    <div class="item-box" style="margin-top: 16px;margin-bottom: 16px;color: #999;">
        <WhiteSpace size='md'></WhiteSpace>
        <img class="user_img" src="{{user.headimgurl}}">
        <div class="txt-title" style="margin-top: 12px;margin-bottom: 12px;">Uid:{{user.id}}</div>
        <List style="width: 90%;margin-bottom: 22px;" [renderHeader]="''">
            <ListItem [arrow]="'horizontal'" [extra]="idinleft" (click)="onIdintidy()">
                <div style="font-size: 12px;">
                    识别码
                </div>
            </ListItem>
            <ListItem [align]="'middle'" [arrow]="'horizontal'" [extra]="mojarleft">
                <div style="font-size: 12px;">
                    选科组合
                    <Brief>
                        <div style="font-size:10px">意向组合</div>
                    </Brief>
                </div>
            </ListItem>
            <ListItem [align]="'middle'" (click)="onEva()" [arrow]="'horizontal'">
                <div style="font-size: 12px;">
                    我的能力测评结果
                </div>
            </ListItem>
            <!--
            <ListItem [align]="'middle'" (click)="onVip()" [arrow]="'horizontal'" [extra]="vipleft">
                <div style="font-size: 12px;">
                    春生涯会员
                </div>
            </ListItem>
            -->
            <ListItem [arrow]="'horizontal'" (click)="onExitSys()">
                <div style="font-size: 12px;">
                    退出登录
                </div>
            </ListItem>
            <ng-template #idinleft>
                <div style="font-size: 11px">{{user.idintidy_no}}</div>
            </ng-template>
            <ng-template #mojarleft>
                <div style="font-size: 11px">{{user_result}}</div>
            </ng-template>
            <ng-template #vipleft>
                <div style="font-size: 11px">{{(vip_info*1000|date:'yyyy-MM-dd')||'成为会员'}}</div>
            </ng-template>
        </List>
    </div>
</div>

<Modal [(ngModel)]="idinVis" [popup]="true" [animationType]="'slide-up'" (onClose)="onClose()">
    <List [renderHeader]="'识别码录入'" [className]="'popup-list'">
        <ListItem>
            <div class="am-list">
                <div class="am-list-body">
                    <InputItem #idin style="font-size: 13px;" (onChange)="inputChange($event)" [value]="idinvalue"
                        [clear]="true" [disabled]="idincheck" [type]="'number'" [extra]="loadding"
                        [content]="idintitle">
                    </InputItem>
                </div>
            </div>
            <ng-template #idintitle>
                <div style="font-size:13px;">识别码</div>
            </ng-template>

            <ng-template #idintext>
                <div *ngIf="idinvalue.length==6" style="font-size:13px;">输入六位数字识别码</div>
            </ng-template>

            <ng-template #loadding>
                <Icon *ngIf="idin.value>100000&&idin.value<999999" [type]="'loading'">
                </Icon>
            </ng-template>

            <Brief *ngIf="msg.length>0">
                <div style="margin-left: 16px;font-size: 11px;margin-bottom: 30px;">{{msg}}</div>
            </Brief>
        </ListItem>
    </List>
</Modal>

<Modal [(ngModel)]="vipinVis" [popup]="true" [animationType]="'slide-up'" (onClose)="onClose()">
    <List [renderHeader]="'会员体验码录入'" [className]="'popup-list'">
        <ListItem>
            <div class="am-list">
                <div class="am-list-body">
                    <InputItem #idin style="font-size: 13px;" maxlength="6" (onChange)="inputChangeVip($event)"
                        [value]="vipvalue" [clear]="true" [disabled]="vipcheck" [type]="'number'" [extra]="viploadding"
                        [content]="viptitle">
                    </InputItem>
                </div>
            </div>
            <ng-template #viptitle>
                <div style="font-size:13px;">会员体验码</div>
            </ng-template>

            <ng-template #viptext>
                <div *ngIf="idinvalue.length==6" style="font-size:13px;">输入六位会员体验码</div>
            </ng-template>

            <ng-template #viploadding>
                <Icon *ngIf="idin.value>100000&&idin.value<999999" [type]="'loading'">
                </Icon>
            </ng-template>

            <Brief *ngIf="msg.length>0">
                <div style="margin-left: 16px;font-size: 11px;margin-bottom: 30px;">{{msg}}</div>
            </Brief>
        </ListItem>
    </List>
</Modal>
<Modal [(ngModel)]="evaVis" [popup]="true" style="height: 60vh;" [animationType]="'slide-up'" (onClose)="onClose()">
    <Accordion [accordion]="true" [defaultActiveKey]="'0'" [activeKey]="activeKey" (onChange)="onChange($event)">
        <AccordionPanel *ngFor="let item of accordions; let i = index" [key]="i" [header]="aheader"
            [disabled]="item.inactive">
            <List className="my-list">
                <ListItem *ngFor="let content of eva[item.child]" wrap>
                    <div>
                        <div class="eva-context">
                            <div>探索时间:{{content.createtime*1000|date:'yyyy-MM-dd'}}</div>
                            <div>
                                <svg t="1659848879558" *ngFor="let i of content.star" class="icon"
                                    viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1335"
                                    width="15" height="15">
                                    <path
                                        d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3-12.3 12.7-12.1 32.9 0.6 45.3l183.7 179.1-43.4 252.9c-1.2 6.9-0.1 14.1 3.2 20.3 8.2 15.6 27.6 21.7 43.2 13.4L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3z"
                                        p-id="1336" fill="#f4ea2a"></path>
                                </svg>
                            </div>
                        </div>
                        <div style="word-break:break-all;">
                            {{ content.text }}
                        </div>
                    </div>
                </ListItem>
            </List>
            <ng-template #aheader>
                <span style="font-size: 12px;">{{item.title}}</span>
            </ng-template>
        </AccordionPanel>
    </Accordion>
</Modal>