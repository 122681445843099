import { Component, OnInit,Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-navtool',
  templateUrl: './navtool.component.html',
  styleUrls: ['./navtool.component.css']
})
export class NavtoolComponent implements OnInit {

  @Input() title: string;
  @Input() backurl:string;
  @Input() mode:string;
  constructor(private router: Router, private route: ActivatedRoute, ) { }

  ngOnInit() {
  }

  onLeftClick(){
    this.router.navigate([this.backurl], { relativeTo: this.route });
  }

}
