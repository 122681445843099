import { Component, OnInit } from '@angular/core';
import { OutMessage, User, ResMessage } from '../../resources/resources';
import { GlobalmessageService } from 'src/app/service/globalmessage.service';
import { MyService } from '../my.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService, ModalRef, ToastService } from 'ng-zorro-antd-mobile';
import { UserSubject } from '../my';

@Component({
  selector: 'app-my-select-subject',
  templateUrl: './my-select-subject.component.html',
  styleUrls: ['./my-select-subject.component.css']
})
export class MySelectSubjectComponent implements OnInit {

  user: User;
  usersubject: UserSubject;
  checkItemListData = [
    { value: 0, name: '物理', checked: false },
    { value: 1, name: '化学', checked: false },
    { value: 3, name: '生物', checked: false },
    { value: 4, name: '历史', checked: false },
    { value: 5, name: '政治', checked: false },
    { value: 6, name: '地理', checked: false }
  ];

  constructor(
    private _gms: GlobalmessageService,
    private _mys: MyService,
    private router: Router,
    private route: ActivatedRoute,
    private _ms: ModalService,
    private _toast: ToastService,
  ) {
    this.user = this._gms.getUserinfo_init();
  }


  ngOnInit() {
    this._mys.getUserSubject().then(data => {
      let tmpdata = data as ResMessage;
      this.usersubject = tmpdata.msg;
      if (tmpdata.msg != '未查询到对应选科信息') {
        this.checkItemListData.forEach((item, index) => {
          let tempItem = item;
          if (item.name == '物理') {
            tempItem.checked = this.usersubject.wl;
          } else if (item.name == '化学') {
            tempItem.checked = this.usersubject.hx;
          } else if (item.name == '生物') {
            tempItem.checked = this.usersubject.sw;
          } else if (item.name == '历史') {
            tempItem.checked = this.usersubject.ls;
          } else if (item.name == '政治') {
            tempItem.checked = this.usersubject.zz;
          } else if (item.name == '地理') {
            tempItem.checked = this.usersubject.dl;
          }
          this.checkItemListData[index] = tempItem;
        })
      } else {
        this.usersubject = new UserSubject();
        this.usersubject.uid = this.user.id;
        this.usersubject.wl = false;
        this.usersubject.hx = false;
        this.usersubject.sw = false;
        this.usersubject.ls = false;
        this.usersubject.zz = false;
        this.usersubject.dl = false;
      }
    })
  }
  onChange = (val: any) => {
    let tmpus = this.usersubject;
    let items = this.checkItemListData.filter(p => p.checked == true)
    let count = items.length;
    let index = this.checkItemListData.findIndex(p => p.value == val.value);
    if (count > 3) {
      val.checked = false;
      this.checkItemListData[index] = val;
    } else {
      if (val.name == '物理') {
        tmpus.wl = val.checked
        //this.usersubject.wl = val.checked;
      } else if (val.name == '化学') {
        tmpus.hx = val.checked;
      } else if (val.name == '生物') {
        tmpus.sw = val.checked;
      } else if (val.name == '历史') {
        tmpus.ls = val.checked;
      } else if (val.name == '政治') {
        tmpus.zz = val.checked;
      } else if (val.name == '地理') {
        tmpus.dl = val.checked;
      }
    }
    this.usersubject = tmpus;
  }

  onSave() {
    const toast = this._toast.loading('保存中...', 0);
    this.usersubject.uid = this.user.id;
    this._mys.updateUserSubject(this.usersubject).then(data => {
      let msg = data as ResMessage;
      if (msg.state == 0) {
        this._gms.setUserSubject(this.usersubject);
        this.router.navigate(['/'], { relativeTo: this.route });
      } else {
        this._ms.alert('系统提示', '保存失败')
      }
      this._toast.hide();
    })
  }

  onLeftClick() {
    this.router.navigate(['/'], { relativeTo: this.route });
  }

}
