<Card>
    <CardHeader [title]="title" [extra]="extra" >
        <ng-template #title>
            <span style="color:#1296db;font-size:13px">{{order.goodname}}</span>
        </ng-template>
        <ng-template #extra>
            <span style="font-size: 11px;">订单编号: {{order.id}}</span>
        </ng-template>
    </CardHeader>
    <CardBody>
        <WhiteSpace [size]="'md'"></WhiteSpace>
        <Flex [justify]="'center'">
            <div style="font-size: 12px;">共{{order.goodnum}}件商品 合计:￥{{order.orderamount}}元</div>
        </Flex>
    </CardBody>
    <CardFooter [content]="footercontent" [extra]="footerExtra">
        <ng-template #footerExtra>
            <span style="font-size: 11px;"></span>
        </ng-template>
        <ng-template #footercontent>
            <span style="font-size: 11px;">订单时间: {{dateConvert(order.createtime)}}</span>
        </ng-template>
    </CardFooter>
</Card>
<WhiteSpace [size]="'md'"></WhiteSpace>