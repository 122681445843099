import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OutMessage, User, ResMessage } from '../../resources/resources';
import { GlobalmessageService } from 'src/app/service/globalmessage.service';
import { MyService } from '../my.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService, ModalRef } from 'ng-zorro-antd-mobile';
import { PickerService, PickerRef } from 'ng-zorro-antd-mobile';

@Component({
  selector: 'app-my-sex',
  templateUrl: './my-sex.component.html',
  styleUrls: ['./my-sex.component.css']
})
export class MySexComponent implements OnInit {

  @Input() user: User;
  @Output() private om = new EventEmitter<OutMessage>();
  name: string;
  sex = '';
  sexvalue = [];
  sex$ = ['男', '女'];
  constructor(
    private _gms: GlobalmessageService,
    private _mys: MyService,
    private router: Router,
    private route: ActivatedRoute,
    private _ms: ModalService,
    private _picker: PickerService,
  ) {
    this.user = this._gms.getUserinfo_init();
    if(!this.user.sex){
      this.sex='选择';
    }else{
      this.sex=this.user.sex;
    }
  }

  ngOnInit() {

  }

  showSexPicker() {
    const ref: PickerRef = this._picker.showPicker(
      { value: this.sexvalue, data: this.sex$, title: '选择性别' },
      result => {
        this.user.sex = this.getResult(result);
        this.sex=this.user.sex;
      },
      cancel => {
        console.log('cancel');
      }
    );

  }

  getResult(result) {
    this.sexvalue = [];
    let temp = '';
    result.forEach(item => {
      this.sexvalue.push(item.label || item);
      temp += item.label || item;
    });
    return this.sexvalue.map(v => v).join(',');
  }

  onSave() {
    this._mys.updateUserInfoSex(this.user).then(data => {
      let msg = data as ResMessage;
      if (msg.state == 0) {
        this._gms.setUserinfo(this.user);
        this.router.navigate(['../', 'mydetail'], { relativeTo: this.route });
      } else {
        this._ms.alert('系统提示', '保存失败')
      }
    })
  }

  onLeftClick() {
    this.router.navigate(['../', 'mydetail'], { relativeTo: this.route });
  }
}
