<app-my-login *ngIf="!showlogin"></app-my-login>
<div *ngIf="showlogin">
  <div class="headerbox">
    <Flex [align]="'start'">
      <img class="headerimg" src="{{user.headimgurl}}" style="background-color: white;" />
      <div class="headertext">
        <WhiteSpace size='md'></WhiteSpace>
        <div class="text">{{user.name}}</div>
        <WhiteSpace size='md'></WhiteSpace>
        <div>ID: {{user.id}}</div>
      </div>
    </Flex>
  </div>
  <div>
    <WhiteSpace size='md'></WhiteSpace>
    <Flex [align]="'start'">
      <List [renderHeader]="" style="width: 100vw;">
        <ListItem [thumb]="'../../assets/icons/my.png'" [arrow]="'horizontal'"
          [routerLink]="[{ outlets: { popup: ['mydetail'] } }]">
          我的资料
        </ListItem>
        <ListItem [thumb]="'../../assets/icons/mysubject.png'" (onClick)="onClick()" [arrow]="'horizontal'"
          [routerLink]="[{ outlets: { popup: ['myselectsubject'] } }]">
          我的选科
        </ListItem>
        <ListItem [thumb]="'../../assets/icons/tec.png'" (onClick)="onClick()" [arrow]="'horizontal'">
          我的老师
        </ListItem>
      </List>
    </Flex>
    <WhiteSpace size='md'></WhiteSpace>
    <List style="width: 100vw;">
      <ListItem [thumb]="'../../assets/icons/cp.png'" [arrow]="'horizontal'">
        我的志愿规划
      </ListItem>
      <ListItem [thumb]="'../../assets/icons/q.png'" [arrow]="'horizontal'" [routerLink]="[{ outlets: { popup: ['myconsult'] } }]">
        我的学业咨询
      </ListItem>
    </List>
    <WhiteSpace size='md'></WhiteSpace>
    <List style="width: 100vw;">
      <ListItem [thumb]="'../../assets/icons/q.png'" [arrow]="'horizontal'" [routerLink]="[{ outlets: { popup: ['myorder'] } }]">
        我的订单
      </ListItem>
    </List>

    <WhiteSpace size='md'></WhiteSpace>
    <List *ngIf="user.type==0" style="width: 100vw;">
      <ListItem [thumb]="'../../assets/icons/key.png'" [arrow]="'horizontal'"
        [routerLink]="[{ outlets: { popup: ['myauthorize'] } }]">
        生成邀请码
      </ListItem>
    </List>
    <WhiteSpace size='md'></WhiteSpace>
    <List style="width: 100vw;">
      <ListItem [thumb]="'../../assets/icons/about.png'" [routerLink]="[{ outlets: { popup: ['myabout'] } }]" [arrow]="'horizontal'">
        关于我们
      </ListItem>
    </List>

    <WhiteSpace size='md'></WhiteSpace>
    <List style="width: 100vw;">
      <ListItem [thumb]="'../../assets/icons/user-2.png'" [arrow]="'horizontal'" (onClick)="onExitLogin()">
        退出登录
      </ListItem>
    </List>
  </div>
</div>