<ng-template #icon>
    <Icon [type]="'left'"></Icon>
</ng-template>
<Navbar class="topnav" style="background-color: #4B8D7F;" [icon]="icon" (onLeftClick)="onLeftClick()">
    学业咨询回复
</Navbar>
<div style="height:45px"></div>
<WingBlank [size]="'md'">
    <WhiteSpace [size]="'md'"></WhiteSpace>
    <Card>
        <CardHeader [title]="title" [extra]="extra">
            <ng-template #title>
                <span style="color:#1296db">{{ask.name}}</span>
            </ng-template>
            <ng-template #extra>
                <span style="font-size: 11px;">咨询时间:{{dateConvert(ask.createtime)}}</span>
            </ng-template>
        </CardHeader>
        <CardBody style="font-size: 12px;color: #999;">
            <List [className]="'my-list'">
                <ListItem>
                    <Flex style="font-size: 12px;color: #999;">
                        <FlexItem>
                            <div>所在城市: {{ask.city}}</div>
                        </FlexItem>
                        <FlexItem>
                            <div>所在年级: {{ask.grade}}</div>
                        </FlexItem>
                    </Flex>
                </ListItem>
                <ListItem>
                    <Flex style="font-size: 12px;color: #999;">
                        <FlexItem>
                            <div>语文</div>
                        </FlexItem>
                        <FlexItem>
                            <div>技能型失分: {{ask.ywj}}</div>
                        </FlexItem>
                        <FlexItem>
                            <div>知识型失分: {{ask.ywz}}</div>
                        </FlexItem>
                    </Flex>
                </ListItem>
                <ListItem>
                    <Flex style="font-size: 12px;color: #999;">
                        <FlexItem>
                            <div>数学</div>
                        </FlexItem>
                        <FlexItem>
                            <div>技能型失分: {{ask.sxj}}</div>
                        </FlexItem>
                        <FlexItem>
                            <div>知识型失分: {{ask.sxz}}</div>
                        </FlexItem>
                    </Flex>
                </ListItem>
                <ListItem>
                    <Flex style="font-size: 12px;color: #999;">
                        <FlexItem>
                            <div>英语</div>
                        </FlexItem>
                        <FlexItem>
                            <div>技能型失分: {{ask.yyj}}</div>
                        </FlexItem>
                        <FlexItem>
                            <div>知识型失分: {{ask.yyz}}</div>
                        </FlexItem>
                    </Flex>
                </ListItem>
                <ListItem>
                    <Flex style="font-size: 12px;color: #999;">
                        <FlexItem>
                            <div>历史</div>
                        </FlexItem>
                        <FlexItem>
                            <div>技能型失分: {{ask.lsj}}</div>
                        </FlexItem>
                        <FlexItem>
                            <div>知识型失分: {{ask.lsz}}</div>
                        </FlexItem>
                    </Flex>
                </ListItem>
                <ListItem>
                    <Flex style="font-size: 12px;color: #999;">
                        <FlexItem>
                            <div>地理</div>
                        </FlexItem>
                        <FlexItem>
                            <div>技能型失分: {{ask.dlj}}</div>
                        </FlexItem>
                        <FlexItem>
                            <div>知识型失分: {{ask.dlz}}</div>
                        </FlexItem>
                    </Flex>
                </ListItem>
                <ListItem>
                    <Flex style="font-size: 12px;color: #999;">
                        <FlexItem>
                            <div>政治</div>
                        </FlexItem>
                        <FlexItem>
                            <div>技能型失分: {{ask.zzj}}</div>
                        </FlexItem>
                        <FlexItem>
                            <div>知识型失分: {{ask.zzz}}</div>
                        </FlexItem>
                    </Flex>
                </ListItem>
                <ListItem>
                    <Flex style="font-size: 12px;color: #999;">
                        <FlexItem>
                            <div>物理</div>
                        </FlexItem>
                        <FlexItem>
                            <div>技能型失分: {{ask.wlj}}</div>
                        </FlexItem>
                        <FlexItem>
                            <div>知识型失分: {{ask.wlz}}</div>
                        </FlexItem>
                    </Flex>
                </ListItem>
                <ListItem>
                    <Flex style="font-size: 12px;color: #999;">
                        <FlexItem>
                            <div>化学</div>
                        </FlexItem>
                        <FlexItem>
                            <div>技能型失分: {{ask.hxj}}</div>
                        </FlexItem>
                        <FlexItem>
                            <div>知识型失分: {{ask.hxz}}</div>
                        </FlexItem>
                    </Flex>
                </ListItem>
                <ListItem>
                    <Flex style="font-size: 12px;color: #999;">
                        <FlexItem>
                            <div>生物</div>
                        </FlexItem>
                        <FlexItem>
                            <div>技能型失分: {{ask.swj}}</div>
                        </FlexItem>
                        <FlexItem>
                            <div>知识型失分: {{ask.swz}}</div>
                        </FlexItem>
                    </Flex>
                </ListItem>
                <ListItem>
                    <Brief style="font-size: 12px;color: #999;">咨询内容</Brief>
                    <Flex style="font-size: 12px;color: #999;">
                        <FlexItem>
                            <div>{{ask.message}}</div>
                        </FlexItem>
                    </Flex>
                </ListItem>
                <ListItem>
                    <TextareaItem style="font-size: 12px;margin-left: -16px;margin-right: -16px;" [rows]="6"
                        [count]="200" (onChange)="inputChange($event)" [placeholder]="'在此处输入咨询回复'"></TextareaItem>
                </ListItem>
                <WhiteSpace size='md'></WhiteSpace>
                <Flex [justify]="'center'">
                    <div Button class="sendbtn" (click)="onSubmit()" [type]="'primary'">咨询回复</div>
                </Flex>
            </List>
        </CardBody>
    </Card>
    <WhiteSpace [size]="'lg'"></WhiteSpace>
</WingBlank>