import { Component, OnInit } from '@angular/core';
import { GlobalmessageService } from '../../../service/globalmessage.service';
import { DocService } from '../doc.service';




@Component({
  selector: 'app-doc-detail',
  templateUrl: './doc-detail.component.html',
  styleUrls: ['./doc-detail.component.css']
})
export class DocDetailComponent implements OnInit {

  doc: any;
  data: any = {};
  constructor(
    private _ags: GlobalmessageService,
    private _ds: DocService,
  ) { }

  ngOnInit() {
    this.data = this._ags.getItems('read');
  }

  async onBest() {
    let tmp = this.data;
    tmp.bestnum=tmp.bestnum+1;
    this.data=tmp;
    await this._ds.best(this.data.id);
  }

}
