import { Component, OnInit } from '@angular/core';
import { ToastService } from 'ng-zorro-antd-mobile';
import { GlobalmessageService } from 'src/app/service/globalmessage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-query-mojar',
  templateUrl: './query-mojar.component.html',
  styleUrls: ['./query-mojar.component.css']
})
export class QueryMojarComponent implements OnInit {
  groups: any = '物理';
  checked = false;
  indeterminate = false;
  hx = false; sw = false; zz = false; dl = false;
  _checkXk = [];
  constructor(
    private _toast: ToastService,
    private _gms: GlobalmessageService,
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  onChange(e) {
    if (e.checked) {
      if (this._checkXk.length >= 2) {
        const toast = this._toast.fail('只能选择两门学科哦！', 1500);
        this.setCheckBox(e.source.id);
        return;
      }
      this._checkXk.push({ name: e.source.id, checked: e.checked });
    } else {
      this._checkXk = this._checkXk.filter(p => p.name != e.source.id)
    }
  }

  setCheckBox(id) {
    switch (id) {
      case '化学':
        this.hx = false;
        break;
      case '生物':
        this.sw = false;
        break;
      case '政治':
        this.zz = false;
        break;
      case '地理':
        this.dl = false;
        break;
    }
  }


  onQuery() {
    if (this._checkXk.length >= 1) {
      //let data = { groups: this.groups, xk: this._checkXk };
      let data = this._checkXk.map(p=>p.name)
      data.push(this.groups)
      this._gms.setItems('lives_query_items', data);
      this.router.navigate(['/query/mojar/class']);
    }else{
      const toast = this._toast.fail('请先选择学科再进行匹配！', 1500);
    }
  }
}
