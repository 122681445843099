import { Component, OnInit } from '@angular/core';

import { GlobalmessageService } from 'src/app/service/globalmessage.service';
import { UserService } from 'src/app/service/user.service';
import { ResMessage, User } from '../resources/resources';
import { ToastService } from 'ng-zorro-antd-mobile';
import { CpService } from '../home/cp/cp.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-userinfo',
  templateUrl: './userinfo.component.html',
  styleUrls: ['./userinfo.component.css']
})
export class UserinfoComponent implements OnInit {
  user: any;
  user_result: string = '';
  idinVis: boolean = false;
  idincheck: boolean = false;
  idinvalue: any;
  msg: string = '';
  vip_info: any;
  vipinVis: boolean = false;
  vipcheck: boolean = false;
  vipvalue: any;
  evaVis: boolean = true;
  eva:any;
  constructor(
    private router: Router,
    private _gms: GlobalmessageService,
    private _us: UserService,
    private _cs: CpService,
    private _toast: ToastService
  ) {
    this.user = _gms.getUserinfo_init();
  }

  ngOnInit() {
    this.getUserResult()
  }

  onIdintidy() {
    this.idinVis = true;
  }

  activeKey = [1];
  accordions: Array<any> = [
    { title: '学习态度探索', child: 3 },
    { title: '学习方法探索', child: 4 },
    { title: '学习习惯探索', child: 5 }
  ];

  onChange(event) {
    console.log(event);
  }

  onEva(){
    console.log(this.eva)
    if(this.eva)
      this.evaVis = true;
    else{
      this._toast.success('请先进行能力探索！')
    }
  }

  onExitSys() {
    this._gms.sendAppMessage({ module: 'app_user', data: new User() });
    this._gms.sendAppMessage({ module: 'app_login', data: false });
    this._gms.sendAppMessage({ module: 'app_wx_login', data: false });
    this._gms.setUserinfo(new User());
    this.router.navigate(['/home']);
  }

  onClose() {
    this.idinVis = false;
    this.vipinVis = false;
    this.evaVis=false;
  }

  async inputChangeVip(e) {
    const value = e.replace(/\s/g, '');
    if (this.idinvalue != value) {
      this.msg = '';
    }
    if (e.length == 6) {
      if (!this.vipvalue || this.vipvalue != value) {
        this.vipvalue = value;
        this.vipcheck = true;
        let msg = await this._us.useVipno(this.vipvalue) as ResMessage;
        if (msg.state == 0) {
          this.onClose();
          this.msg = '';
          this.vipcheck = false;
          this.getUserResult();
          this._toast.success('添加成功！')
        } else {
          this.msg = msg.msg;
          this.vipcheck = false;
        }
      }

    } else {
      this.msg = '请输入6位识别码'
    }
  }

  async inputChange(e) {
    const value = e.replace(/\s/g, '');
    if (this.idinvalue != value) {
      this.msg = '';
    }
    if (e.length == 6) {
      if (!this.idincheck || this.idinvalue != value) {
        this.idinvalue = value;
        this.idincheck = true;
        let msg = await this._cs.checkIdinCode(value) as ResMessage;
        if (msg.state == 0) {
          this.onClose();
          this.user.idintidy_no = e;
          this._gms.setUserinfo(this.user);
          let str = JSON.stringify(this.user);
          this._gms.sendAppMessage({ module: 'app_user', data: this.user });
          this.msg = '';
          this.idincheck = false;
          this._toast.success('添加成功！')
        } else {
          this.msg = msg.msg;
          this.idincheck = false;
        }
      }

    } else {
      this.msg = '请输入6位识别码'
    }

  }

  checkidinty() {
    if (this.user.idintidy_no > 0 && this.user.idintidy_no != null) {
      return false;
    } else {
      return true;
    }
  }

  onVip() {
    if (!this.vip_info) {
      this.vipinVis = true;
    }
  }

  async getUserResult() {
    let result = await this._us.getUserResult() as ResMessage;
    this.eva= await this._us.getEva();
    console.log(this.eva["3"])
    if (result.state == 0) {
      let data = result.msg[0].user_result;
      data = JSON.parse(data)
      this.user_result = data[0] + '、' + data[1] + '、' + data[2];
    }
    let vip_result = await this._us.checkCpToll() as ResMessage;
    if (vip_result.state == 0) {
      this.vip_info = vip_result.msg.exp_time;
    }
  }

}
