export class UserSubject {
        id: number;
        uid: number;
        wl: boolean;
        hx: boolean;
        sw: boolean;
        ls: boolean;
        zz: boolean;
        dl: boolean;
}


export class Authorize{
        id: number;
        uid: number;
        license:string;
        flag:boolean;
        type:number;
}