<div class="context">
    <div class="text" [innerHTML]="data.context|safeHtml"></div>
</div>

<div class="bot">
    <div style="display: flex;flex-direction: column;justify-content: space-between;align-items: center;">
        <img (click)="onBest()" src="../../../../assets/doc/Heart.png"
            style="height: 24px;width: 24px;" />
        <div style="color: #ccc;font-size: 11px;">{{data.bestnum}}</div>
    </div>
    <div
        style="margin-left: 48px;display: flex;flex-direction: column;justify-content: space-between;align-items: center;">
        <img src="../../../../assets/doc/read.png" style="height: 24px;width: 24px;" />
        <div style="color: #ccc;font-size: 11px;">{{data.readnum}}</div>
    </div>
</div>