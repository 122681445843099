<ng-template #icon>
    <Icon [type]="'left'"></Icon>
</ng-template>
<Navbar [icon]="icon" style="background-color: #4B8D7F;" [rightContent]="rightContent" (onLeftClick)="onLeftClick()">
    用户注册
</Navbar>
<ng-template #rightContent>
    <div (click)="onSave()">保存</div>
</ng-template>
<WhiteSpace [size]="'md'"></WhiteSpace>
<div class="am-list" style="margin:0;">
    <div class="am-list-body">
        <InputItem [placeholder]="'输入手机号码'" [clear]="true" [maxLength]="20" [type]="'phone'" (onErrorClick)="errmsg(1)"
            (onChange)="inputChange($event,'phone')" [error]="error" (onBlur)="checkPhone()" [content]="'手机号码'">
        </InputItem>
        <InputItem [placeholder]="'输入姓名'" (onChange)="inputChange($event,'name')" [clear]="true" [content]="'姓名'">
        </InputItem>
        <InputItem [type]="'password'" [placeholder]="'输入密码'" (onErrorClick)="errmsg(2)" (onChange)="inputChange($event,'password')"
            [error]="errorpw" [clear]="true" [maxLength]="18" [content]="'密码'">
        </InputItem>
        <InputItem [type]="'password'" [placeholder]="'再次输入密码'" (onErrorClick)="errmsg(3)" (onChange)="inputChange($event,'nextpassword')"
            [error]="errorpw2" [clear]="true" [maxLength]="18" [content]="'确认密码'">
        </InputItem>
    </div>
</div>
<Flex>
    <FlexItem>
        <AgreeItem style="font-size: 0.75em;color: #999;" data-seed="logId" [name]="agreeItemData.name" [value]="agreeItemData.value"
            [(ngModel)]="agreeItemData.checked">
            同意 <a (click)="onClick($event)" style="color: blue;">用户协议</a>
        </AgreeItem>
    </FlexItem>
</Flex>