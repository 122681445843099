import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalmessageService } from '../service/globalmessage.service';
import { User, ResMessage } from '../pages/resources/resources';
import { MyService } from '../pages/my/my.service';
import { UserService } from 'src/app/service/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _gms: GlobalmessageService,
    private _us: UserService,
    private _ms: MyService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let user = this._gms.getUserinfo_init()
    let boo = Object.keys(user).length > 0;
    let arr = ['/home/cp/query/list', '/home/cp/items/score', '/home/cp/items', '/home/cp/query/list/mojar', '/home/cp/query','/query/mojar','/query/mojar/class']
    let cpinx = arr.findIndex(p => p == state.url) > -1;
    let arr_toll = ['/home/cp/query/list', '/home/cp/query/list/mojar'];
    let toll_boo = arr_toll.findIndex(p => p == state.url) > -1;
    if (boo) {
      if (cpinx) {
        let pp_id = this._gms.getItems('lives_3_cp_upid');
        if (!pp_id) {
          this.router.navigate(['/home/cp']);
        } else {
          return true;
          if (toll_boo) {
            //判断是否缴费
            return this._us.checkCptollNa(res=>{
              let toll_result = res as ResMessage;
              if (toll_result.state == 0) {
                return true;
              } else {
                this._gms.setItems('toll_back_url', state.url)
                this.router.navigate(['/home/cp/query/toll']);
                return false;
              }
            })

          } else {
            return true;
          }
        }
      } else {
        if (toll_boo) {
          //判断是否缴费
          /*
          return this._us.checkCptollNa(res=>{
            let toll_result = res as ResMessage;
            if (toll_result.state == 0) {
              return true;
            } else {
              this._gms.setItems('toll_back_url', state.url)
              this.router.navigate(['/home/cp/query/toll']);
              return false;
            }
          })
          */
          return true;
        } else {
          return true;
        }
      }

    } else {
      this._gms.sendAppMessage({ module: 'app_login', data: false });
      this._gms.sendAppMessage({ module: 'app_user', data: new User() });
      this._gms.sendAppMessage({ module: 'app_wx_login', data: false });
      this._gms.setItems('app_back_url', state.url)
      this.router.navigate(['/my']);
      return false;
    }
  }
}
