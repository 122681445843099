import { Cp, CpItems } from './cp';

export const CpInfo: Cp = {
    id: 1,
    title: '选科决策',
    context: '3+1+2选科，是高中生面临的第一次重大决策！请结合自身实际，从支撑学科发展的背景因素入手，运用决策平衡单技术，探索出最适合自己发展的优势学科组合。再将优势学科与高校选科要求进行匹配，设计出最适合自己的大学升学路径！',
    createuser: 'admin',
    createtime: 2019111111,
    cpnum: 1,
    cpvalue: 99,
    cpdiscountvalue: 199,
    cpdiscount: 0,
    flag: true
}


export const CpItemsList: CpItems[] = [
    {
        id: 1, num: 1, titlestart: '从', titleend: '的角度对各学科进行匹配',
        keystring: '学科兴趣', breif: '（学科兴趣：是指一个人从事学习、工作等活动的内在心理需求，是学生学科学习的基础保障。）'
    },
    {
        id: 2, num: 2, titlestart: '从', titleend: '的角度对各学科进行匹配',
        keystring: '学习能力', breif: '（学习能力：是指学生运用独立地获取信息，加工和利用信息，分析和解决实际问题的一种个性特征。）'
    },
    {
        id: 3, num: 3, titlestart: '从', titleend: '的角度对各学科进行匹配',
        keystring: '学习方法', breif: '（学习方法：是指通过学习实践总结出的快速掌握知识的方法，学习方法与学习过程掌握知识的效率密切相关。）'
    },
    {
        id: 4, num: 4, titlestart: '从', titleend: '（师资力量）的角度对各学科进行匹配',
        keystring: '教学优势', breif: '（教学优势：是指学科教师的专业文化水平，教学水平，自身道德修养和其它为人之师所不可少的综合素质、教学条件，师资力量就指上述资质的水平程度。）'
    },
    {
        id: 5, num: 5, titlestart: '从', titleend: '的角度对各学科进行匹配',
        keystring: '升学目标', breif: '（升学目标：新高考背景下的人才培养，许多大学专业对高中学科做了要求，你的高中学科与目标大学专业的学科要求匹配程度）'
    },
    {
        id: 6, num: 6, titlestart: '从', titleend: '的角度对各学科进行匹配',
        keystring: '初中成绩', breif: '（初中各学科成绩体现了一定的学习方法与学习能力，各学科初中的学习成绩一定程度上体现了高中学科的学习能力）'
    },
]