<div style="display: flex;align-items: center;justify-content: center;flex-direction: column;padding-bottom: 18px;background-color: #fff;">
    <div style="width: 80vw;display: flex;justify-content: left;margin-top: 36px;flex-direction: column;">
        <div>{{sb.name}}</div>
        <div style="display: flex;justify-content: left;margin-top: 8px;">
            <Tag [small]="true">985</Tag>
            <Tag [small]="true" class="tagblack">211</Tag>
            <Tag [small]="true" class="tagblack">{{sb.zone}}</Tag>
            <Tag [small]="true" class="tagblack">院校编号:{{sb.sid}}</Tag>
        </div>
    </div>
    <div style="width: 80vw;display: flex;justify-content: left;margin-top: 16px;flex-direction: column;">
        <div>{{se.year+'年录取情况'}}</div>
        <div style="display: flex;justify-content: left;margin-top: 8px;">
            <Tag [small]="true">{{se.subject}}</Tag>
            <Tag [small]="true" class="tagblack">{{se.batch}}</Tag>
        </div>
        <div style="display: flex;justify-content: space-between;margin-top: 8px;">
            <div style="width: 50%;font-size: 11px;color: #999;">计划数: {{se.plan}}</div>
            <div style="width: 50%;font-size: 11px;color: #999;">录取数: {{se.enrollment}}</div>
        </div>
        <div style="display: flex;justify-content: space-between;margin-top: 8px;">
            <div style="width: 50%;font-size: 11px;color: #999;">最高分: {{se.peak}}</div>
            <div style="width: 50%;font-size: 11px;color: #999;">最低分: {{se.lowest}}</div>
        </div>
    </div>

    <div style="width: 80vw;display: flex;justify-content: left;margin-top: 16px;flex-direction: column;">
        <div>{{m.name}}</div>
        <div style="font-size: 12px; color: #000;line-height: 20px;margin-top: 8px;">培养要求:</div>
        <div style="font-size: 12px; color: #999;line-height: 20px;inset-inline: 2em;">
            本专业培养具有一定的马克思主义理论素养，具备较全面的宗教学知识，了解世界各大宗教的历史与现状，熟悉我国宗教法规和政策，
            能在高等院校、研究机构或政府部门从事教学、研究、宗教事务管理、理论宣传、政策调研等工作的宗教学高级专门人才。
        </div>
        <div style="font-size: 12px; color: #000;line-height: 20px;margin-top: 8px;">培养目标:</div>
        <div style="font-size: 12px; color: #999;line-height: 20px;inset-inline: 2em;">
            本专业学生主要学习宗教学的基本理论，较全面地了解世界各大宗教的历史与现状以及我国的宗教法规和政策，接受独立思考、分析问题、社会调研等方面的基本训练。
            毕业生应获得以下几方面的知识和能力：
            1．掌握马克思主义的基本原理和宗教学的基本理论，具有关于世界主要宗教的基本知识；
            2．掌握现代宗教学的主要研究方法；
            3．了解世界宗教的发展动态和宗教研究的前沿问题；
            4．了解我国的宗教法规和政策；
            5．具有独立思考、分析问题的基本能力；
            6．掌握文献检索、社会调查的基本方法，具有初步的教学、科研和实际工作能力。
        </div>
        <div style="font-size: 12px; color: #000;line-height: 20px;margin-top: 8px;">核心课程:</div>
        <div style="font-size: 12px; color: #999;line-height: 20px;inset-inline: 2em;">
            中国哲学史、外国哲学史、东方哲学、宗教学导论、佛教史、道教史、基督教史、伊斯兰教史、民间宗教研究、宗教社会学、宗教心理学、宗教问题社会调查与方法、宗教学原著选读等。
        </div>
        <div style="font-size: 12px; color: #000;line-height: 20px;margin-top: 8px;">优势院校:</div>
        <div style="font-size: 12px; color: #999;line-height: 20px;inset-inline: 2em;">
            北京大学、南开大学、复旦大学、山东大学、中央民族大学、中国人民大学、湖北经济学院
        </div>
        <div style="font-size: 12px; color: #000;line-height: 20px;margin-top: 8px;">匹配职业及发展方向:</div>
        <div style="font-size: 12px; color: #999;line-height: 20px;inset-inline: 2em;">
            在高等院校、研究机构或政府部门从事教学、研究、宗教事务管理、理论宣传、政策调研等工作。到政府部门从事宗教事务管理工作，或到高等院校和科研机构进行教学科研，或活跃于新闻出版、文化交流等领域。
        </div>
    </div>
</div>