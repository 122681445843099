<div class="page">
    <div class="title" style="height: 44.44vw;">
      <img src="../../../../assets/new/home/index/LOGO.png" style="width: 60px;height:60px;">
    </div>
    <div class="content" style="min-height: 70vh">
      <div class="item-box" style="margin-top: 16px;margin-bottom: 16px;">
        <WhiteSpace [size]="'lg'"></WhiteSpace>
        <div style="color:#999;font-size: 13px;margin-top: 16px;">选择以下科目组合</div>
        <mat-radio-group [(ngModel)]="groups" aria-label="Select an option" style="margin-top: 13px;">
            <mat-radio-button [color]="'primary'" value="物理" style="font-size: 12px;">物理组</mat-radio-button>
            <mat-radio-button [color]="'primary'" style="margin-left: 12px;font-size: 12px;" value="历史">历史组</mat-radio-button>
        </mat-radio-group>
        <section class="example-section">
          <mat-checkbox id="化学" [color]="'primary'" (change)="onChange($event)" class="example-margin" [(ngModel)]="hx">化学</mat-checkbox>
          <mat-checkbox id="生物" [color]="'primary'" (change)="onChange($event)" class="example-margin" [(ngModel)]="sw">生物</mat-checkbox>
          <mat-checkbox id="政治" [color]="'primary'" (change)="onChange($event)" class="example-margin" [(ngModel)]="zz">政治</mat-checkbox>
          <mat-checkbox id="地理" [color]="'primary'" (change)="onChange($event)" class="example-margin" [(ngModel)]="dl">地理</mat-checkbox>
        </section>
          <div class="btn" style="margin-top: 8px;margin-bottom: 32px;" (click)="onQuery()">开始匹配</div>
      </div>
    </div>
  </div>