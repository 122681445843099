import { Component, OnInit } from '@angular/core';
import { OutMessage, User, ResMessage, Order } from '../../../resources/resources';
import { GlobalmessageService } from 'src/app/service/globalmessage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService, ModalRef } from 'ng-zorro-antd-mobile';
import { PickerService, PickerRef } from 'ng-zorro-antd-mobile';
import { OrdersService } from 'src/app/service/orders.service';

@Component({
  selector: 'app-deliver',
  templateUrl: './deliver.component.html',
  styleUrls: ['./deliver.component.css']
})
export class DeliverComponent implements OnInit {
  name: string;
  express = '';
  expressvalue = [];
  express$ = ['顺丰速运', 'EMS', '德邦快递', '宅急送', '韵达速递', '圆通速递'];
  user = new User();
  order: Order;
  constructor(
    private _gms: GlobalmessageService,
    private _ods: OrdersService,
    private router: Router,
    private route: ActivatedRoute,
    private _ms: ModalService,
    private _picker: PickerService,
  ) {
    this.user = this._gms.getUserinfo_init();
    this.order = this._gms.getItems('myorder');
    this.express = this.order.delivery || 'EMS';
  }

  ngOnInit() {
  }

  onSave() {
    if (!this.order.delivery) {
      this.order.delivery = 'EMS';
    }
    this._ods.updateStatusDelivery(this.order.id, this.order.delivery || 'EMS', this.order.expressnumber).then(data => {
      console.log(data)
      let tmp = data as ResMessage;
      if (tmp.state == 0) {
        this._gms.setItems('myorder', this.order);
        this.router.navigate([{ outlets: { popup: 'myorder' } }]);
      } else {
        this._ms.alert('系统提示', '保存失败,请稍后再试。');
      }
    })
  }

  showSexPicker() {
    const ref: PickerRef = this._picker.showPicker(
      { value: this.expressvalue, data: this.express$, title: '选择快递' },
      result => {
        this.express = this.getResult(result);
        this.order.delivery = this.express;
      },
      cancel => {
        console.log('cancel');
      }
    );

  }

  getResult(result) {
    this.expressvalue = [];
    let temp = '';
    result.forEach(item => {
      this.expressvalue.push(item.label || item);
      temp += item.label || item;
    });
    return this.expressvalue.map(v => v).join(',');
  }

  onLeftClick() {
    this.router.navigate([{ outlets: { popup: 'myorderdetail' } }]);
  }

  inputChange(e) {
    this.order.expressnumber = e;
  }

}
