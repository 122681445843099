<ng-template #icon>
  <Icon [type]="'left'"></Icon>
</ng-template>
<Navbar [icon]="icon" style="background-color: #4B8D7F;" [rightContent]="rightContent" (onLeftClick)="onLeftClick()">
  姓名
</Navbar>
<ng-template #rightContent>
  <div (click)="onSave()">保存</div>
</ng-template>
<WhiteSpace [size]="'md'"></WhiteSpace>
<InputItem [clear]="true" [value]="user.name" (onChange)="inputChange($event)" [content]="'姓名'">