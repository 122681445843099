import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Subject } from 'rxjs';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { OutMessage, User } from '../pages/resources/resources';
import { UserSubject } from '../pages/my/my';
import { MajorSelectValues, MajorCheckEnrollment } from '../pages/marjors/major';

@Injectable({
  providedIn: 'root'
})
export class GlobalmessageService {

  private _message = new Subject<any>();
  private _wxcode = new Subject<any>();
  private _viewsindex = new Subject<number>();
  private _usermessage = new Subject<OutMessage>();
  private _loginMessage = new Subject<boolean>();
  private _userinfo = new Subject<User>();
  private _usersubject = new Subject<UserSubject>();
  private _cpmsv = new Subject<MajorSelectValues>();
  private _app_message = new Subject<any>();

  @LocalStorage('lives_userinfo', 1, 'd') cpmsv: MajorSelectValues;
  @LocalStorage('lives_login_status', 30, 'd') lives_login_status: boolean = false;
  @LocalStorage('lives_userinfo', 30, 'd') lives_userinfo: User;
  @LocalStorage('lives_user_token', 30, 'd') lives_user_token: string;
  @LocalStorage('_lives_wx_status', 30, 'd') _lives_wx_status: boolean = false;

  constructor(public local: LocalStorageService, public session: SessionStorageService) {

  }

  getItems(key: string): any {
    let cpant = this.local.get(key);
    return cpant;
  }

  setItems(key: string, value: any) {
    this.local.set(key, value, 1, 'd');
  }

  clearItems(key: string) {
    this.local.remove(key);
  }

  setAppTempLog(str: string) {
    this.local.set('apptemplog', str, 1, 'd');
  }

  getAppTempLog() {
    let cpant = this.local.get('apptemplog');
    if (!cpant)
      cpant = '';
    return cpant;
  }

  setAppinfo(str: string) {
    this.local.set('appinfo', str, 1, 'd');
  }

  getAppinfo() {
    let cpant = this.local.get('appinfo');
    if (!cpant)
      cpant = '';
    return cpant;
  }

  setCpMce(msv: MajorCheckEnrollment) {
    this.local.set('cpmce', msv, 1, 'd');
  }

  getCpMce() {
    let cpant = this.local.get('cpmce');
    if (!cpant)
      cpant = new MajorCheckEnrollment();;
    return cpant;
  }

  getCpAnt() {
    let cpant = this.local.get('cpant');
    if (!cpant)
      cpant = [];
    return cpant;
  }

  setCpAnt(msv: any) {
    this.local.set('cpant', msv, 1, 'd');
  }

  getCpItems() {
    let cpitems = this.local.get('cpitems');
    if (!cpitems)
      cpitems = '';
    return cpitems;
  }

  setCpItems(msv: string) {
    this.local.set('cpitems', msv, 1, 'd');
  }

  getCpMsvOne() {
    let cpmsv = this.local.get('cpmsv');
    if (!cpmsv)
      cpmsv = new MajorSelectValues();
    return cpmsv;
  }

  setCpMsv(msv: MajorSelectValues) {
    this.local.set('cpmsv', msv, 1, 'd');
    this._cpmsv.next(msv);
  }

  getCpMsv(): Observable<MajorSelectValues> {
    return this._cpmsv.asObservable();
  }

  setUserSubject(us: UserSubject) {
    this.local.set('usersubject', us, 1, 'd');
    this._usersubject.next(us);
  }

  getUserSubject_init() {
    let cpitems = this.local.get('usersubject');
    if (!cpitems)
      cpitems = new UserSubject;
    return cpitems;
  }

  getUserSubject(): Observable<UserSubject> {
    return this._usersubject.asObservable();
  }

  setLoginStatus(status: boolean) {
    this.lives_login_status = status;
    this._loginMessage.next(status);
  }

  getLoginStatus(): Observable<boolean> {
    let status = this.local.get('lives_login_status');
    if (!status) {
      status = false;
    } else {
      this.lives_login_status = status;
    }
    return this._loginMessage.asObservable();
  }

  setWxStatus(status: boolean) {
    this.local.set('_lives_wx_status', status, 30, 'd');
  }

  getWxStatus(): Observable<boolean> {
    let status = this.local.get('_lives_wx_status');
    if (!status)
      status = false;
    return status;
  }

  getWxstatus() {
    let status = this.local.get('_lives_wx_status');
    if (!status)
      status = false;
    return status;
  }


  setWxCode(code: string) {
    this._wxcode.next(code);
  }

  getWxCode(): Observable<string> {
    return this._wxcode.asObservable();
  }

  getLoginStatus_init() {
    let status = this.local.get('lives_login_status');
    if (!status) {
      status = false;
    } else {
      this.lives_login_status = status;
    }
    return status;
  }
  getUserinfo_init() {
    let userinfo = this.local.get('lives_userinfo');
    if (!userinfo)
      userinfo = new User();
    return userinfo;
  }

  set_lives_user_token(token: string) {
    this.local.set('lives_user_token', token, 30, 'd');
  }

  get_lives_user_token() {
    let userinfo = this.local.get('lives_user_token');
    return userinfo || '';
  }

  setUserinfo(user: User) {
    this.local.set('lives_userinfo', user, 30, 'd');
    this.lives_userinfo = user;
    this._userinfo.next(user);
  }

  getUserinfo(): Observable<any> {
    let userinfo = this.local.get('lives_userinfo');
    if (!userinfo)
      userinfo = new User();
    return this._userinfo.asObservable();
  }

  setViewIndex(index: number) {
    this.local.set('viewindex', index);
    this._viewsindex.next(index);
  }

  getViewIndex(): Observable<any> {
    let index = this.local.get('viewindex');
    if (!index)
      index = 0;
    return this._viewsindex.asObservable();
  }


  setUserMessage(msg: OutMessage) {
    this.local.set('lives_user_detail', msg);
    this._usermessage.next(msg);
  }

  getUserMessage(): Observable<any> {
    let user = this.local.get('lives_user_detail');
    if (!user)
      user = new OutMessage();
    return this._usermessage.asObservable();
  }

  //发送消息
  sendMessage(message: string) {
    this._message.next(message);
  }
  //清除消息
  clearMessage() {
    this._message.next();
  }
  //获取消息
  receiveMessage(): Observable<any> {
    return this._message.asObservable();
  }

  //发送消息
  sendAppMessage(message: any) {
    this._app_message.next(message);
  }
  //清除消息
  clearAppMessage() {
    this._app_message.next();
  }
  //获取消息
  receiveAppMessage(): Observable<any> {
    return this._app_message.asObservable();
  }

}
