<ng-template #icon>
    <Icon [type]="'left'"></Icon>
</ng-template>
<Navbar [icon]="icon" style="background-color: #4B8D7F;" [rightContent]="rightContent" (onLeftClick)="onLeftClick()">
    发货登记
</Navbar>
<ng-template #rightContent>
    <div (click)="onSave()">保存</div>
</ng-template>
<WhiteSpace [size]="'md'"></WhiteSpace>
<List className="my-list">
    <ListItem [extra]="express" [arrow]="'horizontal'" (click)="showSexPicker()">
            快递
    </ListItem>
    <InputItem [clear]="true" [value]="order.expressnumber" [placeholder]="'请输入快递单号'" (onChange)="inputChange($event)" [content]="'快递单号'"></InputItem>
</List>