import { Component, OnInit } from '@angular/core';
import { ResMessage } from '../../resources/resources';
import { DocService } from '../doc.service';
import { GlobalmessageService } from '../../../service/globalmessage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-doc-list',
  templateUrl: './doc-list.component.html',
  styleUrls: ['./doc-list.component.css']
})
export class DocListComponent implements OnInit {

  datasource: any = [];
  constructor(
    private router: Router,
    private _ds: DocService,
    private _ags: GlobalmessageService
  ) { }

  ngOnInit() {
    this.getData();
  }

  async getData() {
    let result = await this._ds.get() as ResMessage;
    this.datasource = result.msg;
  }

  async onRead(item) {
    item.readnum=item.readnum+1;
    this._ags.setItems('read', item);
    await this._ds.read(item.id);
    this.router.navigate(['/doc/item']);
  }

}
