<ng-template #icon>
    <Icon [type]="'left'"></Icon>
</ng-template>
<Navbar [icon]="icon" class="topnav" [mode]="''" [rightContent]="rightContent" (onLeftClick)="onLeftClick()">
    生涯规划
</Navbar>
<ng-template #rightContent>
    <div (click)="onPay()">购买</div>
</ng-template>
<div style="height:100%;">
    <div style="height:45px"></div>
    <WhiteSpace size='lg'></WhiteSpace>
    <WingBlank size='md'>
        <Flex [justify]="'center'">
            <div style="font-size: 16px;font-weight: bold;">中学《生涯规划》教材简介</div>
        </Flex>
        <WhiteSpace size='md'></WhiteSpace>
        <div class="line-text">结合高中教育教学的实际，本教材的编写从三个方面考虑，即可自学、可授课、可解惑。</div>
        <div class="item-text"><span
                style="font-weight: bold;color:#000">可自学：</span>对于没有能开展生涯教育地区的同学可以通过教材内容的自学，从中掌握到与自己学业发展息息相关的方法与技巧；
        </div>
        <div class="item-text"><span
                style="font-weight: bold;color:#000">可授课：</span>对于已经开展生涯教育的地区，老师围绕教材中的内容，引导学生以探索专业、职业为抓手，促进学业提升为核心，
            引导学生学习相应的生涯规划技术，拓宽教学内容，有效避免生涯教育“空对空”的窘境；</div>
        <div class="item-text"><span
                style="font-weight: bold;color:#000">可解惑：</span>对于即将升入高中和高中在读的学生、家长而言，更是一本难得的生涯规划实操手册。教材编写注重实用性，对目前高
            中生的文理分科、3+1+2选科、大学专业选择、高中学业管理、高中学法指导等内容做了流程化梳理，让同学们在学习过程中有获得感，学业成绩上有提升。</div>
        <WhiteSpace size='md'></WhiteSpace>
        <div style="font-weight: bold;color:#000;width:100%;text-align: center;">《生涯规划》统一售价 49.8 元</div>
        <WhiteSpace size='md'></WhiteSpace>
        <WingBlank size='md'>
            <div style="line-height:23px">
                <div style="font-weight: bold;color:#000">目录及内容展示</div>
                <div style="font-weight: bold;color:#000">第一章 高中生涯的门与路</div>
                <div style="color:#999">第一节 敲开生涯之门</div>
                <div style="color:#999">第二节 走好生涯之路</div>
                <div style="color:#999">第三节 高中人际与时间管理</div>
                <div style="font-weight: bold;color:#000">第二章 自我探索</div>
                <div style="color:#999">第一节 发现我的能力</div>
                <div style="color:#999">第二节 发现快乐的自己</div>
                <div style="color:#999">第三节 充实成长中的自己</div>
                <div style="font-weight: bold;color:#000">第三章 职业探索</div>
                <div style="color:#999">第一节 了解我国的职业分类</div>
                <div style="color:#999">第二节 初探职业</div>
                <div style="color:#999">第三节 职业体验</div>
                <div style="font-weight: bold;color:#000">第四章 升学规划</div>
                <div style="color:#999">第一节 认识专业</div>
                <div style="color:#999">第二节 专业发展</div>
                <div style="color:#999">第三节 我的大学面面观</div>
                <div style="font-weight: bold;color:#000">第五章 生涯能力提升</div>
                <div style="color:#999">第一节 决策能力</div>
                <div style="color:#999">第二节 决策技术实践与应用</div>
                <div style="color:#999">第三节 优势学科发展与选科决策</div>
                <div style="font-weight: bold;color:#000">第六章 学业规划</div>
                <div style="color:#999">第一节 探索学业目标</div>
                <div style="color:#999">第二节 学业进程面面观</div>
                <div style="color:#999">第三节 高中应试面面观</div>
            </div>
        </WingBlank>
        <WhiteSpace size='md'></WhiteSpace>
        <img src="assets/shop/sygh/1.png">
        <img src="assets/shop/sygh/4.png">
        <img src="assets/shop/sygh/5.png">
    </WingBlank>
    <WhiteSpace size='lg'></WhiteSpace>
</div>