import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MyDetailComponent } from './my-detail/my-detail.component';
import { GlobalmessageService } from '../../service/globalmessage.service';
import { UserSubject } from './my';
import { MyService } from './my.service';
import { User, WxUserinfo, ResMessage } from '../resources/resources';
import { PayService } from 'src/app/service/pay.service';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-my',
  templateUrl: './my.component.html',
  styleUrls: ['./my.component.css']
})
export class MyComponent implements OnInit {

  message: string = '';
  m = 100;
  user: User;
  showlogin: boolean = false;
  code: string = '';
  wxUserinfo: WxUserinfo;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _ms: MyService,
    private _gms: GlobalmessageService,
    private _us: UserService,
    private _pays: PayService) {
    //信息接收处理
    this._gms.receiveAppMessage().subscribe(msg => {
      if (msg != null) {
        if (msg.module == 'app_user') {
          this.user = msg.data as User;
          this.showlogin = true;
        } else if (msg.module == 'app_login') {
          //this.showlogin = msg.data
          this.showlogin = false;
          if (!msg.data) {
            //this.onWxAuth();

          }
        }
      } else {
        this.showlogin = false;
      }
    });

    this._gms.getWxCode().subscribe(code => {
      if (code != null) {
        this.getWxUserinfo(code);
      } else {
        this.code = '';
      }
    });
  }
  async ngOnInit() {
    await this._us.getUser();
  }

  async onClickPay() {
    let msg = await this._pays.getPaySign('Test pay', this.user.openid, 1) as ResMessage;
    if (msg.state == 0) {
      (window as any).WeixinJSBridge.invoke(
        'getBrandWCPayRequest', msg.msg,
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          }
        });
    }

  }

  onWxAuth() {
    let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=';
    url += 'wx6504e4b07e3bf7c9';
    url += '&redirect_uri=';
    url += encodeURIComponent('https://www.zzsygh.com');
    url += '&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect'
    this._gms.setWxStatus(true);
    window.location.href = url;
  }

  getWxUserinfo(code) {
    if (!this.showlogin) {
      this._ms.getOpenid(code);
    }
  }

  onExitLogin() {
    this._gms.sendAppMessage({ module: 'app_user', data: new User });
    this._gms.sendAppMessage({ module: 'app_login', data: false });
    this._gms.sendAppMessage({ module: 'app_wx_login', data: false });
  }

  onSendMsg() {
    this._gms.sendMessage(this.m.toString())
    this.m = this.m + 1;
  }

  onClick(type: number) {

  }

}
