import { Injectable } from '@angular/core';
import { DbsService } from './dbs.service';
import { School, Zone } from '../pages/resources/resources';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  constructor(private dbs: DbsService) {
  }

  async getAppInfo(){

    return await this.dbs.get('resource/appinfo');
  }

  async getMajorResource() {
    return await this.dbs.get('resource');
  }
}
