//天赋测评
export class Cp {
    id: number;
    title: string;
    context: string;
    createuser: string;
    createtime: number;
    cpnum: number;
    cpvalue: number;
    cpdiscountvalue: number;
    cpdiscount: number;
    flag: boolean;
}

//测评题目
export class CpItems {
    id: number;
    num: number;
    titlestart: string;
    titleend: string;
    keystring: string;
    breif: string;
}

//测评结论
export class CpConclusion {
    id: number;
    userid: number;
    values: string;
    conclusion: string;
    iftransteacher: boolean;
    iftransstudents: boolean;
    iftransparents: boolean;
    ifcharges: boolean;
    chargesvalues: number;
}

//测评答案
export class CpAnswer {
    id: number;
    serialnumber: number;
    title: string;
    answerlistid: string;
    answer: string;
    weightvalue: string;
    createuser: string;
    createtime: number;
    flag: boolean;
}

export class CpAnswerValues {
    id: number;
    cpid: number;
    userid: number;
    answerid: number;
    answernum: number;
    answer: number;
    submitanswer: number;
    submittite: number;
    ifright: boolean;
}

export class CpAnwMessage {
    issueid: number;
    value: any;
    num:number;
}

export class CpAnwList {
    num: number;
    anw: CpAnw;
}

export class CpAnw {
    wl: string;
    hx: string;
    sw: string;
    ls: string;
    zz: string;
    dl: string;
}

export class CpSore {
    type: number;
    wl: number;
    sw: number;
    hx: number;
    ls: number;
    zz: number;
    dl: number;
}


export interface answer {
    id: number,
    issue_id: number,
    context: string,
    num: number,
    unite_id: number,
    checked: number
}

export interface issue {
    id: number,
    title: string,
    answer: string,
    plan_id: number,
    num: number
}

export interface Plan {
    id: number,
    name: string,
    select_type: number,
    issue: number,
    context: string
}