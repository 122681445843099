<div class="page">
    <div class="title" style="height: 44.44vw;">
        <img src="../../../../assets/new/home/index/LOGO.png" style="width: 60px;height:60px;">
    </div>
    <div class="content">
        <div class="item-box" style="margin-top: 16px;margin-bottom: 16px;">
            <List style="width: 100%;" [renderHeader]="lsttitle">
                <ListItem *ngFor="let item of dataview" multipleLine [extra]="lstleft">
                    <div style="font-size: 12px">{{item.school_name}}</div>
                    <Brief>
                        <div style="font-size: 11px">院校代码：{{item.sid}}</div>
                    </Brief>
                    <ng-template #lstleft>
                        <div style="font-size: 11px">{{item.batch}}(录取情况)</div>
                    </ng-template>
                </ListItem>
            </List>
            <ng-template #lsttitle>
                <div style="font-size: 12px;margin-left: 14px;margin-top: 12px;margin-bottom: 6px;">专业对应院校信息&nbsp;&nbsp;&nbsp;&nbsp;（{{subject_item.mojar_name}}）</div>
                <div style="font-size: 12px;margin-left: 14px;margin-top: 6px;margin-bottom: 6px;
                    display: flex;flex-wrap: wrap;align-content: space-between;align-items: center;">
                    批次：<CheckboxItem *ngFor="let i of checkItemListData" [name]="i.name" [value]="i.value"
                        [(ngModel)]="i.checked" (onChange)="onChange($event)">
                        <div style="font-size: 12px;">{{ i.name }}</div>
                    </CheckboxItem>
                </div>
                <div style="font-size: 11px;margin-left: 14px;margin-bottom: 12px;color: #999;">符合院校数：{{viewlength}} 所</div>
            </ng-template>
        </div>
    </div>
</div>