import { Component, OnInit, Input } from '@angular/core';
import { Ask } from '../../home/askthetutor/askthetutor';

@Component({
  selector: 'app-my-consult-list',
  templateUrl: './my-consult-list.component.html',
  styleUrls: ['./my-consult-list.component.css']
})
export class MyConsultListComponent implements OnInit {

  @Input() ask: Ask;
  constructor() { }

  ngOnInit() {

  }

  checkTime(timestamp){
    let timespan = new Date().getTime() / 1000;
    let m = Math.floor((timespan - timestamp) / 3600)
    return m+'h'
  }

  dateConvert(timestamp) {
    var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    return Y + M + D + h + m + s;
  }

}
