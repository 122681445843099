import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SelectivePreloadingStrategyService } from './selective-preloading-strategy.service';
import { MyDetailComponent } from './pages/my/my-detail/my-detail.component';
import { ViewsComponent } from './views/views.component';
import { MyImageComponent } from './pages/my/my-image/my-image.component';
import { MyNameComponent } from './pages/my/my-name/my-name.component';
import { HomeComponent } from './pages/home/home.component';
import { MajorDetailComponent } from './pages/marjors/major-detail/major-detail.component';
import { MySexComponent } from './pages/my/my-sex/my-sex.component';
import { MySelectSubjectComponent } from './pages/my/my-select-subject/my-select-subject.component';
import { MyAuthorizeComponent } from './pages/my/my-authorize/my-authorize.component';
import { MyRegisterComponent } from './pages/my/my-register/my-register.component';
import { MyWxAuthComponent } from './pages/my/my-wx-auth/my-wx-auth.component';
import { MyConsultComponent } from './pages/my/my-consult/my-consult.component';
import { MyConsultDetailComponent } from './pages/my/my-consult-detail/my-consult-detail.component';
import { AuthGuard } from './auth/auth.guard';
import { ShopComponent } from './pages/shop/shop.component';
import { MyOrdersComponent } from './pages/my/my-orders/my-orders.component';
import { ShopDetailComponent } from './pages/shop/shop-detail/shop-detail.component';
import { SyghComponent } from './pages/shop/shop-detail/sygh/sygh.component';
import { ShopChecksComponent } from './pages/shop/shop-checks/shop-checks.component';
import { MyOrdersDetailComponent } from './pages/my/my-orders/my-orders-detail/my-orders-detail.component';
import { MyAboutComponent } from './pages/my/my-about/my-about.component';
import { DeliverComponent } from './pages/my/my-orders/deliver/deliver.component';
import { RefundComponent } from './pages/my/my-orders/refund/refund.component';
import { MajorItemComponent } from './pages/marjors/major-item/major-item.component';
import { MyComponent } from './pages/my/my.component';
import { DictAcademicComponent } from './pages/manager/dict-academic/dict-academic.component';
import { UserinfoComponent } from './pages/userinfo/userinfo.component'
import { QueryMojarComponent } from './pages/query/query-mojar/query-mojar.component';
import { QueryMojarClassComponent } from './pages/query/query-mojar/query-mojar-class/query-mojar-class.component';
import { QueryMojarListComponent } from './pages/query/query-mojar/query-mojar-list/query-mojar-list.component'
import { QueryMojarSchoolComponent } from './pages/query/query-mojar/query-mojar-school/query-mojar-school.component';

const routes: Routes = [
  { path: '', redirectTo: '/home',pathMatch:'full' },
  //{ path: '', component: AppComponent },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModuleModule)
  },
  {
    path: 'doc',
    loadChildren: () => import('./pages/doc/doc.module').then(m => m.DocModule)
  },
  {
    path: 'marjor',
    loadChildren: () => import('./pages/marjors/marjors.module').then(m => m.MarjorsModule)
  },
  
  { path: 'mydetail', component: MyDetailComponent },
  { path: 'myimage', component: MyImageComponent },
  { path: 'myname', component: MyNameComponent },
  { path: 'mysex', component: MySexComponent },
  { path: 'myselectsubject', component: MySelectSubjectComponent },
  { path: 'myorder', component: MyOrdersComponent },
  { path: 'shopdetail', component: ShopDetailComponent },
  { path: 'majordetail', component: MajorDetailComponent },
  { path: 'major/item', component: MajorItemComponent },
  { path: 'myauthorize', component: MyAuthorizeComponent },
  { path: 'myregister', component: MyRegisterComponent },
  { path: 'mywxauth', component: MyWxAuthComponent },
  { path: 'myconsult', component: MyConsultComponent },
  { path: 'myorderdetail', component: MyOrdersDetailComponent },
  { path: 'myconsultdetail', component: MyConsultDetailComponent },
  { path: 'shopdetailsygh', component: SyghComponent },
  { path: 'shopcheck', component: ShopChecksComponent },
  { path: 'myabout', component: MyAboutComponent },
  { path: 'orderdeliver', component: DeliverComponent },
  { path: 'orderrefund', component: RefundComponent },
  { path: 'my', component: MyComponent },
  { path: 'manager/dict/acade', component: DictAcademicComponent },
  { path: 'userinfo', component: UserinfoComponent,canActivate:[AuthGuard]},
  { path: 'query/mojar', component: QueryMojarComponent },
  { path: 'query/mojar/class', component: QueryMojarClassComponent },
  { path: 'query/mojar/class/list', component: QueryMojarListComponent },
  { path: 'query/mojar/class/list/school', component: QueryMojarSchoolComponent }
  //{ path: 'view', component: ViewsComponent, outlet: 'popup', canActivate: [AuthGuard]},
  //{ path: 'mydetail', component: MyDetailComponent, outlet: 'popup' },
  //{ path: 'myimage', component: MyImageComponent, outlet: 'popup' },
  //{ path: 'myname', component: MyNameComponent, outlet: 'popup' },
  //{ path: 'mysex', component: MySexComponent, outlet: 'popup' },
  //{ path: 'myselectsubject', component: MySelectSubjectComponent, outlet: 'popup' },
  //{ path: 'home', component: HomeComponent, outlet: 'popup' },
  // { path: 'myorder', component: MyOrdersComponent, outlet: 'popup' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false, // <-- debugging purposes only
    preloadingStrategy: SelectivePreloadingStrategyService,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
