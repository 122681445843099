
<div class="mainview">
    <div class="title" style="height: 44.44vw;">
        <img src="../../../../assets/new/home/index/LOGO.png" style="width: 60px;height:60px;">
    </div>
    <WhiteSpace size='md'></WhiteSpace>
    <div class="item-box" style="margin-top: 16px;margin-bottom: 16px;color: #999;">
        <WhiteSpace size='md'></WhiteSpace>
        <p>{{message}}</p>
        <WhiteSpace size='md'></WhiteSpace>
    </div>
</div>