import { Component, OnInit } from '@angular/core';
import { GlobalmessageService } from '../../../service/globalmessage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../service/user.service';
import { MyService } from '../my.service';

@Component({
  selector: 'app-my-about',
  templateUrl: './my-about.component.html',
  styleUrls: ['./my-about.component.css']
})
export class MyAboutComponent implements OnInit {

  constructor(
    private _gms: GlobalmessageService,
    private router: Router,
    private route: ActivatedRoute,
    private _us: UserService,
    private _ms: MyService
  ) { }

  async ngOnInit() {
    await this._us.getUser();
  }
}
