<div class="page">
  <div class="title" style="height: 44.44vw;">
    <img src="../../../../assets/new/home/index/LOGO.png" style="width: 60px;height:60px;">
  </div>
  <div class="content">
    <!--20220924 
      <div class="item-box" style="margin-top: 16px;margin-bottom: 16px;">
        <WhiteSpace [size]="'lg'"></WhiteSpace>
        <List style="width: 100%;" [renderHeader]="lsttitle">
          <ListItem *ngFor="let item of datasource" multipleLine [extra]="lstleft" [arrow]="'horizontal'"
            (onClick)="onClick(item)">
            <div style="font-size: 12px">{{item.sc.subject_class_name}}</div>
          
            <Brief><div style="font-size: 11px">{{item.sc.select_type}}</div></Brief>

    <ng-template #lstleft>
      <div style="font-size: 11px">匹配专业{{item.mnum}}个</div>
    </ng-template>
    </ListItem>
    <ng-template #lsttitle>
      <div style="display: flex;justify-content: space-between;margin-bottom: 12px;">
        <div style="font-size: 13px;margin-left: 14px">我的选科结果与匹配专业</div>
        <div style="font-size: 11px;margin-right: 28px;color: blue;">{{user_result}}</div>
      </div>
    </ng-template>
    </List>
  </div>
  -->


    <div class="item-box" style="margin-top: 16px;margin-bottom: 16px;">
      <WhiteSpace [size]="'lg'"></WhiteSpace>
      <List style="width: 100%;" [renderHeader]="lsttitle">
        <div class="search-v">
          <div>
            <InputItem style="padding-left: 14px;font-size: 12px;" [clear]="true" [moneyKeyboardAlign]="'right'"
              [placeholder]="'输入学校名称进行检索'" (onChange)="onSchChange($event)" [content]="sch"></InputItem>
            <ng-template #sch>
              <span style="font-size: 12px;color: blue;">检索学校</span>
            </ng-template>
          </div>
          <div style="width: 80px;padding-top: 9px; font-size: 12px;color: blue;text-align: center;height:30px;line-height: 30px;text-align: center;" Picker [data]="zoneItems" [(ngModel)]="zone" (ngModelChange)="onChange($event)">
            {{zn}}
          </div>

        </div>


        <PullToRefresh [ngStyle]="dtPullToRefreshStyle" [direction]="state.direction" [(ngModel)]="state.refreshState"
          [endReachedRefresh]="state.endReachedRefresh" (onRefresh)="pullToRefresh($event)">
          <ListItem (onClick)="onClick(item)" *ngFor="let item of state.data" [extra]="lstleft" [arrow]="'horizontal'">
            <div style="font-size: 12px">{{item.sname}}</div>
            <ng-template #lstleft>
              <div style="font-size: 11px">匹配专业{{item.num}}个</div>
            </ng-template>
          </ListItem>
          <ng-template #loading>
            <Icon type="loading"></Icon>
          </ng-template>
        </PullToRefresh>
        <ng-template #le>
          <div style="font-size: 13px;margin-left: 14px">
            <input style="width:120px">
          </div>
        </ng-template>
        <ng-template #lsttitle>
          <div style="display: flex;justify-content: space-between;margin-bottom: 12px;">
            <div style="font-size: 13px;margin-left: 14px">我的选科结果与匹配专业</div>
            <div style="font-size: 11px;margin-right: 28px;color: blue;">{{user_result}}</div>
          </div>
        </ng-template>
      </List>

    </div>
  </div>
</div>