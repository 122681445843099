import { Injectable } from '@angular/core';
import { DbsService } from '../../service/dbs.service';

@Injectable({
  providedIn: 'root'
})
export class DocService {

  constructor(
    private dbs: DbsService
  ) { }

  async get() {
    let result = await this.dbs.get('doc/get', {},)
    return result;
  }

  async read(id) {
    let result = await this.dbs.get('doc/read', { id: id })
    return result;
  }

  async best(id) {
    let result = await this.dbs.get('doc/best', { id: id })
    return result;
  }
}
