import { Component, OnInit } from '@angular/core';
import { ResMessage, User, Order } from '../../../resources/resources';
import { GlobalmessageService } from '../../../../service/globalmessage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService, ModalRef } from 'ng-zorro-antd-mobile';
import { ToastService } from 'ng-zorro-antd-mobile';
import { OrdersService } from '../../../../service/orders.service';

@Component({
  selector: 'app-my-orders-detail',
  templateUrl: './my-orders-detail.component.html',
  styleUrls: ['./my-orders-detail.component.css']
})
export class MyOrdersDetailComponent implements OnInit {

  state = {
    selected: ''
  };

  user: User;
  order: Order;
  constructor(
    private _ors: OrdersService,
    private router: Router,
    private route: ActivatedRoute,
    private _modal: ModalService,
    private _toast: ToastService,
    private _gms: GlobalmessageService,
  ) { }

  ngOnInit() {
    this.order = this._gms.getItems('myorder');
  }

  onSelect(event) {
    console.log(event.id == '1');
    if (event.id == '1')
      this.router.navigate([{ outlets: { popup: 'orderdeliver' } }]);
    else if (event.id == '0')
      this.router.navigate([{ outlets: { popup: 'orderrefund' } }]);
  }

  onVisibleChange(event) {

  }

  onLeftClick() {
    this.router.navigate([{ outlets: { popup: 'myorder' } }]);
  }

  checkTime(timestamp) {
    let timespan = new Date().getTime() / 1000;
    let m = Math.floor((timespan - timestamp) / 3600)
    return m + 'h'
  }

  dateConvert(timestamp) {
    var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    return Y + M + D + h + m + s;
  }

}
