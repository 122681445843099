import { Injectable } from '@angular/core';
import { User } from '../../resources/resources';
import { DbsService } from '../../../service/dbs.service';
import { Ask } from './askthetutor';

@Injectable({
  providedIn: 'root'
})
export class AskthetutorService {

  constructor(
    private _dbs: DbsService
  ) { }

  async add(args) {
    let result = await this._dbs.get('ask/add', args)
    return result;
  }

  async getUser(args) {
    let result = await this._dbs.get('ask/get', args)
    return result;
  }

  async getTec(args) {
    let result = await this._dbs.get('ask/get/tec', args)
    return result;
  }

  async updateReply(ask:Ask){
    let result = await this._dbs.get('ask/update/tec/reply', ask)
    return result;
  }

}
