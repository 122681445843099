<div style="height: 100vh;">
    <Navbar [leftContent]="icon"  style="background-color: #4B8D7F;" [rightContent]="popover" (onLeftClick)="onLeftClick()">
        订单信息
    </Navbar>
    <ng-template #icon>
        <Icon [type]="'left'"></Icon>
      </ng-template>
    <ng-template #popover>
        <Icon Popover [ngStyle]="{ height: '100%', display: 'flex', 'align-items': 'center' }" [mask]="true"
            [showArrow]="true" [overlay]="overlay" [type]="'ellipsis'" [placement]="'bottomRight'"
            (onSelect)="onSelect($event)" (onVisibleChange)="onVisibleChange($event)"></Icon>
    </ng-template>

    <ng-template #overlay>
        <PopoverItem *ngIf="order.status==1" [icon]="icon1">申请退款</PopoverItem>
        <PopoverItem *ngIf="order.status==1 && user.type==0" [icon]="icon2" [ngStyle]="{ whiteSpace: 'nowrap' }">发货登记</PopoverItem>
        <PopoverItem *ngIf="order.status==1 && user.type==0" [icon]="icon3">
            <span [ngStyle]="{ marginRight: 5 }">完成退款</span>
        </PopoverItem>
    </ng-template>

    <ng-template #icon1>
        <Icon [src]="'https://gw.alipayobjects.com/zos/rmsportal/tOtXhkIWzwotgGSeptou.svg'" [size]="'xs'"></Icon>
    </ng-template>

    <ng-template #icon2>
        <Icon [src]="'https://gw.alipayobjects.com/zos/rmsportal/PKAgAqZWJVNwKsAJSmXd.svg'" [size]="'xs'"></Icon>
    </ng-template>

    <ng-template #icon3>
        <Icon [src]="'https://gw.alipayobjects.com/zos/rmsportal/uQIYTFeRrjPELImDRrPt.svg'" [size]="'xs'"></Icon>
    </ng-template>
    <div></div>
    <List [className]="'my-list'">
        <ListItem>
            <div style="text-align: center;"><span style="font-size: 12px;">{{order.goodname}}</span></div>
        </ListItem>
        <ListItem>
            <div style="text-align: center;"><span style="font-size: 14px;color:red">-{{order.orderamount}}</span></div>
        </ListItem>
        <ListItem>
            <div style="text-align: center;"><span style="font-size: 11px;color:#999">交易成功</span></div>
        </ListItem>
        <ListItem [extra]="id"><span style="font-size: 11px;color:#999">订单编号：</span></ListItem>
        <ListItem [extra]="createtime"><span style="font-size: 11px;color:#999">创建时间:</span></ListItem>
        <ListItem [extra]="paytime"><span style="font-size: 11px;color:#999">付款时间:</span></ListItem>
        <ListItem [extra]="flashtime"><span style="font-size: 11px;color:#999">成交时间:</span></ListItem>
        <ng-template #id>
            <span style="font-size:11px">{{order.id}}</span>
        </ng-template>
        <ng-template #createtime>
            <span style="font-size:11px">{{dateConvert(order.createtime)}}</span>
        </ng-template>
        <ng-template #paytime>
            <span style="font-size:11px">{{dateConvert(order.paytime)}}</span>
        </ng-template>
        <ng-template #flashtime>
            <span style="font-size:11px">{{dateConvert(order.flashtime)}}</span>
        </ng-template>
    </List>

</div>