import { Component, OnInit } from '@angular/core';
import { AskthetutorService } from '../../home/askthetutor/askthetutor.service';
import { ResMessage, User } from '../../resources/resources';
import { GlobalmessageService } from '../../../service/globalmessage.service';
import { Ask } from '../../home/askthetutor/askthetutor';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService, ModalRef } from 'ng-zorro-antd-mobile';
import { ToastService } from 'ng-zorro-antd-mobile';

@Component({
  selector: 'app-my-consult-detail',
  templateUrl: './my-consult-detail.component.html',
  styleUrls: ['./my-consult-detail.component.css']
})
export class MyConsultDetailComponent implements OnInit {

  ask: Ask;
  constructor(
    private _asks: AskthetutorService,
    private router: Router,
    private route: ActivatedRoute,
    private _modal: ModalService,
    private _toast: ToastService,
    private _gms: GlobalmessageService,
  ) { }

  ngOnInit() {
    this.ask = this._gms.getItems('conask');
  }

  onLeftClick() {
    this.router.navigate(['../', 'myconsult'], { relativeTo: this.route });
  }

  dateConvert(timestamp) {
    var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    return Y + M + D + h + m + s;
  }

  onSubmit() {
    if (this.ask.replymessage.length > 5) {
      this._modal.alert('温馨提示', '是否提交咨询回复?', [
        { text: '取消', onPress: () => console.log('取消') },
        {
          text: '提交', onPress: () => {
            this.ask.flag=4;
            this._asks.updateReply(this.ask).then(res => {
              let data = res as ResMessage;
              if (data.state == 0) {
                this._toast.info('回复成功', 1500, null, false);
                this.router.navigate(['../', 'myconsult'], { relativeTo: this.route });
              }else{
                this._toast.info('回复失败,请稍后再试.', 1500, null, false);
              }
            })
          }
        }
      ]);
    }
  }

  inputChange(e) {
    this.ask.replymessage = e;
  }

}
