import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResMessage } from 'src/app/pages/resources/resources';
import { DbsService } from 'src/app/service/dbs.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { GlobalmessageService } from 'src/app/service/globalmessage.service';

@Component({
  selector: 'app-query-mojar-school',
  templateUrl: './query-mojar-school.component.html',
  styleUrls: ['./query-mojar-school.component.css']
})
export class QueryMojarSchoolComponent implements OnInit {

  datasource: any;
  dataview: any;
  mid: number;
  subject_item: any;
  viewlength: number = 0;
  checkItemListData = [
    { value: 0, name: '一本', checked: true },
    { value: 1, name: '二本', checked: false },
    { value: 2, name: '高职', checked: false }
  ];

  batchs = ['本科第一批', '本科第二批', '高职专科批'];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dbs: DbsService,
    private _bottomSheet: MatBottomSheet,
    private _gms: GlobalmessageService
  ) { }

  ngOnInit() {
    this.subject_item = this._gms.getItems('lives_cp_mojar_item');
    this.getData(this.subject_item.id)
  }

  onChange = (val: any) => {
    this.filter_data();
  }

  filter_data() {
    let items = this.checkItemListData.filter(p => p.checked == true);
    switch (items.length) {
      case 0:
        this.dataview = [];
        break;
      case 1:
        this.dataview = this.datasource.filter(p => p.batch == this.batchs[items[0].value]);
        break;
      case 2:
        this.dataview = this.datasource.filter(p => p.batch == this.batchs[items[0].value] || p.batch == this.batchs[items[1].value]);
        break;
      case 3:
        this.dataview = this.datasource;
        break;
    }
    this.viewlength = this.dataview.length;
  }

  async getData(mid) {
    let result = await this.dbs.get('cp/get/majorschool', { mid: mid }) as ResMessage;
    if (result.state == 0) {
      this.datasource = result.msg
      this.filter_data();
    }else{
      console.log(result.msg)
    }
  }
}
