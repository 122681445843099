import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OutMessage, User, ResMessage } from '../../resources/resources';
import { GlobalmessageService } from 'src/app/service/globalmessage.service';
import { MyService } from '../my.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService, ModalRef } from 'ng-zorro-antd-mobile';

@Component({
  selector: 'app-my-name',
  templateUrl: './my-name.component.html',
  styleUrls: ['./my-name.component.css']
})
export class MyNameComponent implements OnInit {

  @Input() user: User;
  @Output() private om = new EventEmitter<OutMessage>();
  name: string;
  constructor(
    private _gms: GlobalmessageService,
    private _mys: MyService,
    private router: Router,
    private route: ActivatedRoute,
    private _ms: ModalService,
  ) {
    this.user = this._gms.getUserinfo_init();
  }

  ngOnInit() {

  }

  onSave() {
    this._mys.updateUserInfoName(this.user).then(data => {
      let msg = data as ResMessage;
      if (msg.state == 0) {
        this._gms.setUserinfo(this.user);
        this.router.navigate(['../', 'mydetail'], { relativeTo: this.route });
      } else {
        this._ms.alert('系统提示', '保存失败')
      }
    })
  }

  inputChange(e) {
    this.user.name = e;
  }

  onLeftClick(){
    this.router.navigate(['../', 'mydetail'], { relativeTo: this.route });
  }

}
